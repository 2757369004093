import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import triangleIcon from "../media/icons/Q-Triangle.png";

import connect from "../media/connect.png";
import learn from "../media/learn.png";
import see from "../media/see.png";

import { CaretRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function QHome() {
  const [styleConn, setStyleConn] = useState({ display: "", i: -1 });
  const [datamain, setData] = useState([
    {
      id: 1,
      title: "Connect",
      description:
        "Quantum suceeds if we are all involved. \r\nPlease use the following links to discover \r\nmore.",
      backgroundUrl: "connect",
      items: [
        {
          id: 11,
          title: "What is Quantum",
          url: "/what-is-quantum",
          mainId: 1,
          main: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
        {
          id: 2,
          title: "Quantum Impact",
          url: "/q-impact",
          mainId: 1,
          main: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
        {
          id: 15,
          title: "Quantum Phases",
          url: "/q-phases",
          mainId: 1,
          main: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
        {
          id: 1,
          title: "Quantum Team",
          url: "/meet-the-team",
          mainId: 1,
          main: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
      ],
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-25T13:59:29.327",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 2,
      title: "Learn",
      description:
        "Travel with us.  Be Quantum. Without you, there is no Quantum and no success.",
      backgroundUrl: "learn",
      items: [
        {
          id: 3,
          title: "Employee Guide",
          url: "/employee-guide",
          mainId: 2,
          main: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
        {
          id: 6,
          title: "QPass",
          url: "/q-pass",
          mainId: 2,
          main: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
        {
          id: 5,
          title: "Claim Rewards",
          url: "/claim-rewards",
          mainId: 2,
          main: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
        {
          id: 10,
          title: "My Learning",
          url: "/mylearning",
          mainId: 2,
          main: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
      ],
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-25T13:59:29.327",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 3,
      title: "See",
      description: "Quantum Video Library",
      backgroundUrl: "see",
      items: [
        {
          id: 7,
          title: "Watch our Videos",
          url: "/watch-videos",
          mainId: 3,
          main: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
        {
          id: 9,
          title: "Q Gallery",
          url: "/gallery",
          mainId: 3,
          main: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
      ],
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-25T13:59:29.327",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 4,
      title: "1",
      description: "Quantum - our digital transformation \r\nproject.",
      backgroundUrl: "",
      items: [],
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-11-15T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 5,
      title: "1",
      description:
        "In the next 3 years we will be digitising,\r\nautomating, innovating and transforming\r\nour entire group.",
      backgroundUrl: "",
      items: [],
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-11-15T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 6,
      title: "1",
      description:
        "This will help our businesses to innovate,\r\nand improve the way we work, improve\r\nthe way serve our customers and help\r\nour organisation to become leaner and\r\nmore competitive and grow faster.",
      backgroundUrl: "",
      items: [],
      p_user: "",
      create_byName: "",
      createdon: "0001-01-01T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 7,
      title: "1",
      description:
        "In a nutshell, Quantum will help us to\r\ntransform and become an organisation\r\nready for the new digital era that is\r\nmoving at rapid speed.",
      backgroundUrl: "",
      items: [],
      p_user: "",
      create_byName: "",
      createdon: "0001-01-01T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 8,
      title: "1",
      description:
        "Overtime we aim to become leaders in\r\neach area we operate in.",
      backgroundUrl: "",
      items: [],
      p_user: "",
      create_byName: "",
      createdon: "0001-01-01T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 9,
      title: "1",
      description: "Innovate  >  Transform  >  Lead",
      backgroundUrl: "",
      items: [],
      p_user: "",
      create_byName: "",
      createdon: "0001-01-01T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 10,
      title: "2",
      description:
        "We have grown significantly over the \r\nlast 42 years but our systems and \r\nprocesses supporting our businesses \r\nalso need to change to support this \r\nexciting growth.",
      backgroundUrl: "",
      items: [],
      p_user: "",
      create_byName: "",
      createdon: "0001-01-01T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 11,
      title: "2",
      description:
        "The systems that will be implemented \r\nunder Quantum will help our group to \r\nbecome faster, more efficient and \r\ndrive growth.",
      backgroundUrl: "",
      items: [],
      p_user: "",
      create_byName: "",
      createdon: "0001-01-01T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 12,
      title: "2",
      description:
        "Quantum’s first mission is to replace \r\nour current ERP system.",
      backgroundUrl: "",
      items: [],
      p_user: "",
      create_byName: "",
      createdon: "0001-01-01T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 13,
      title: "2",
      description:
        "We will be moving to a state-of-the-art \r\ncloud-based ERP system called Oracle \r\nFusion.",
      backgroundUrl: "",
      items: [],
      p_user: "",
      create_byName: "",
      createdon: "0001-01-01T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 14,
      title: "2",
      description:
        "This system is the world’s first true \r\npublic cloud system used by over \r\n20,000 businesses across the globe.",
      backgroundUrl: "",
      items: [],
      p_user: "",
      create_byName: "",
      createdon: "0001-01-01T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 15,
      title: "2",
      description:
        "It comes bundled with digital tools \r\nsuch as Artificial Intelligence (AI) and \r\nMachine Learning (ML) that will help \r\nour employees to work smarter and \r\ndeliver more value to our organisation.",
      backgroundUrl: "",
      items: [],
      p_user: "",
      create_byName: "",
      createdon: "0001-01-01T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
  ]);

  const [location, setLocation] = useState(window.location.pathname);
  useEffect(() => {
    let mm = document.getElementById("main-menu")?.getElementsByTagName("li");
    for (let index = 0; index < mm?.length; index++) {
      const element = mm[index];
      if (element) {
        element.classList.remove("ant-menu-item-selected");
        element.classList.remove("ant-menu-item-active");
      }
    }
    if (mm?.length > 1) {
      if (
        window.location.pathname == "/q-phases" ||
        window.location.pathname == "/q-impact" ||
        window.location.pathname == "/meet-the-team" ||
        window.location.pathname == "/what-is-quantum" ||
        window.location.pathname == "/connect"
      ) {
        mm[1].classList.add("ant-menu-item-selected");
      }
      if (
        window.location.pathname == "/admin" ||
        window.location.pathname == "/"
      ) {
        mm[0].classList.add("ant-menu-item-selected");
      }
      if (
        window.location.pathname == "/employee-guide" ||
        window.location.pathname == "/claim-rewards" ||
        window.location.pathname == "/mylearning" ||
        window.location.pathname == "/learn"
      ) {
        mm[1].classList.add("ant-menu-item-selected");
      }
      if (
        window.location.pathname == "/watch-videos" ||
        window.location.pathname == "/gallery" ||
        window.location.pathname == "/see"
      ) {
        mm[3].classList.add("ant-menu-item-selected");
      }
      if (window.location.pathname == "/q-pass") {
        mm[5].classList.add("ant-menu-item-selected");
      }
    }
  }, [location]);
  // useEffect(() => {

  //   if(styleConn.display == 'none')
  //   {
  //     // var highestTimeoutId = setTimeout(";");
  //     // console.log("list ",highestTimeoutId);
  //     // for (var i = 0 ; i < highestTimeoutId ; i++) {
  //     //     clearTimeout(i);
  //     //     console.log("cleared" , i)
  //     // }

  //   }else{

  //     // const interval = setInterval(() => {
  //     //   if(styleConn.display == 'block'){

  //     //   }

  //     //   console.log('show content every 3 s');
  //     // }, 3000);
  //   }

  // }, [styleConn]);
  useEffect(() => {
    if (location == "/") {
      stopintr();
      startintr();
    } else {
      stopintr();
      let f =
        location == "/connect"
          ? 0
          : location == "/learn"
          ? 1
          : location == "/see"
          ? 2
          : -1;
      if (f == -1) {
        startintr();
      } else {
        setStyleConn({ display: "none", i: f });
      }
    }
  }, [location]);
  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);

  function startintr() {
    setInterval(() => {
      let prev = styleConn;
      prev.display = "none";
      if (prev.i != 2) {
        prev.i = prev.i + 1;
      } else {
        prev.i = 0;
      }
      setStyleConn({ display: prev.display, i: prev.i });
    }, 3000);
  }
  function stopintr() {
    const interval_id = window.setInterval(function () {},
    Number.MAX_SAFE_INTEGER);

    // Clear any timeout/interval up to that id
    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }
  }
  return (
    <Row className="cont-row">
      <Col
        className="cont-col-text"
        xs={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 5 }}
      >
        <p className="desc-main">
          Welcome to Quantum – the name & symbol of our exciting digital
          transformation journey. Quantum will help our group innovate,
          transform & lead in each of the sectors that we operate in.
        </p>
        <br />
        <div className="main-desc-div">
          <img
            src={triangleIcon}
            style={{
              width: "0.8rem",
              marginRight: "0.5rem",
              marginTop: "0.3rem",
            }}
          />
          <p className="desc-main-v">
            Click on Connect to get access to all project related information.
            The Why, the What & the When.
          </p>
        </div>
        <div className="main-desc-div">
          <img
            src={triangleIcon}
            style={{
              width: "0.8rem",
              marginRight: "0.5rem",
              marginTop: "0.3rem",
            }}
          />
          <p className="desc-main-v">
            Click on Learn to see all the great stuff that we have for you to
            learn, grow & develop.
          </p>
        </div>
        <div className="main-desc-div">
          <img
            src={triangleIcon}
            style={{
              width: "0.8rem",
              marginRight: "0.5rem",
              marginTop: "0.3rem",
            }}
          />
          <p className="desc-main-v">
            Click on See to view the exciting videos that we will add as we go
            through the various stages of our journey.
          </p>
        </div>

        {/* <p className="desc-main" >
                {datamain?datamain[3]?.description:''}
              </p> */}
      </Col>
      {datamain?.map(
        (main, index) =>
          index >= 0 &&
          index < 3 && (
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 6 }}
              className="cont-col-connect"
              id={`cont-col-${index}`}
              style={{
                backgroundImage: `url(${
                  main.backgroundUrl == "connect"
                    ? connect
                    : main.backgroundUrl == "see"
                    ? see
                    : learn
                })`,
              }}
              onMouseEnter={(e) => {
                stopintr();
                setStyleConn({ display: "none", i: index });
              }}
              onMouseLeave={(e) => {
                startintr();
                setStyleConn({ display: "block", i: index });
              }}
            >
              <p
                className="title-sub"
                style={{
                  display:
                    styleConn.display == "none" && styleConn.i == index
                      ? "none"
                      : "block",
                }}
              >
                {styleConn.display == "none" && styleConn.i == index
                  ? "none"
                  : main.title}
              </p>
              <div
                className="cont-content"
                style={{
                  display:
                    styleConn.display == "none" && styleConn.i == index
                      ? "block"
                      : "none",
                }}
              >
                <p className="title">{main.title}</p>

                <p className="desc">{main.description}</p>
                <ul>
                  {main.items?.map((item, i) => (
                    <li
                    // onClick={() => {
                    //   setLocation(item.url);
                    // }}
                    >
                      <Link to={item.url}>{item.title}</Link>

                      <CaretRightOutlined />
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          )
      )}
    </Row>
  );
}
