// global.js
// Source: https://github.com/maximakymenko/react-day-night-toggle-app/blob/master/src/global.js#L23-L41

import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  html {
    --antd-wave-shadow-color: #00AC40;
    --scroll-bar: 0;
}
.ant-drawer-header ,.ant-menu{
  background: ${({ theme }) => theme.body} !important;
    color: ${({ theme }) => theme.text} !important;
}
body {  
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    font-family :'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'
}
.login-page{
  margin:5% 0;
}
.ant-table-thead > tr > th {
  position: relative;
  
  font-weight: 500;
  text-align: left;
  
  border-bottom: 1px solid #303030;
  transition: background 0.3s ease;
}
h1,h2,h3,h4,h5,h6{
    color: ${({ theme }) => theme.text} !important;
}
.emptyoops{
  margin:1rem auto;
  height:50vh;
  width:auto;
}
.ant-layout{
    background:${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
}
.ant-layout-header{
    background:${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
}
.ant-layout-footer{
    background:${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
}



.show{
  display:block !important;
}
.hide{
  display:none !important;
}
body {
  padding: 0;
}
.states > div > p{
  margin-bottom: 0;
}
.ant-input-search-with-button{
  max-width: 450px;
  
}
.qr-container__qr-code {
  padding: 0 !important;
  border-radius: 0 !important;
  margin: 0 !important;
  background: none !important;
}
.ant-modal-confirm-btns {
  display: none;
}
.modal-arrows{
  position: absolute;
  width: 100%;
  font-size: 39px;
  padding: 0 1%;
  height: 92%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-arrows span{
  background: rgba(0,0,0,0.2);
  padding: 1%;
    /* box-shadow: 0px 0px 11px 5px rgb(0 0 0 / 50%); */
}
.watch-row{
  column-gap: 1rem;
}
.video-viewer{
  width: 100%;
}
.ant-modal-footer{
  display: none !important;
}
.ant-modal-body {
  padding: 0 !important;
  line-height: 0 !important;
}
.watch-player{
  width: 100% !important;
  height: auto !important;
}
.claendar-guide{
  display: grid;
  grid-auto-flow: column;
  text-align: left;
  font: normal normal normal 8px/25px Montserrat;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.text};
  justify-content: space-around;
  padding-bottom: 15px;
}
.ant-picker-cell{
  color: ${({ theme }) => theme.textdisapled};
  
}
.ant-picker-cell-in-view{
color: ${({ theme }) => theme.text};
}
.ant-picker-date-panel .ant-picker-content th{
  text-align: center;
font: normal normal normal 9px/25px Montserrat;
letter-spacing: 0px;
color: ${({ theme }) => theme.text};
}
.ant-picker-calendar .ant-picker-panel{
  border: 0 !important;
  background: ${({ theme }) => theme.textboxbackground} 0% 0% no-repeat padding-box !important;
  padding: 30px 30px 15px;
}
.events {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  height: 100%;
}
.events p{
  margin: 0;
  text-align: center;
  font: normal normal 500 16px/32px Montserrat;
  border-radius: 6px;
}
.events p.missed{
  background: #FF684D 0% 0% no-repeat padding-box;
}
.events p.upcoming{
  background: #669EED 0% 0% no-repeat padding-box;
}
.events p.complete{
  background: #00AC40 0% 0% no-repeat padding-box;
}
.ant-picker-cell div.events p{
  opacity: 0.5 ;
}
.ant-picker-cell.ant-picker-cell-in-view div.events p{
opacity: 1 !important;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}

.trainings{
  display: grid;
  grid-auto-flow: row;
  row-gap: 10px;

  /* overflow: auto; */
  scroll-behavior: auto;
}
.course{
  background: #5A6069 0% 0% no-repeat padding-box;
  border-radius: 6px;
  display: grid;
  grid-auto-flow: column;
  column-gap: 13px;
  align-items: center;
  padding: 10px 18px;
  grid-template-columns: repeat(1, 62px 25fr 1fr);

}
.course-title{
  text-align: left;
  font: normal normal 500 16px/25px Montserrat;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin: 0;
}
.course-datetime{
  text-align: left;
  font: normal normal normal 10px/25px Montserrat;
  letter-spacing: 0px;
}
.course-datetime p{
  margin: 0;
}
.class-separator{
  padding: 0 5px;
  color: #FFFFFF;
}
.course-time{
  color: #00AC40;
}
.course-dept{
  text-align: center;
  font: normal normal 500 16px/25px Montserrat;
  letter-spacing: 0px;
  color: #FFFFFF;
  background: #848A95 0% 0% no-repeat padding-box;
  border-radius: 6px;
  padding: 18px 7px;
  margin: 0;
}
.course-details{
  display: grid;
  grid-auto-flow: row;
  justify-items: start;
  justify-content: start;
}
.course-datetime{
  display: grid;
  grid-auto-flow: column;
}
.available-training{
  width: 100%;
  background: ${({ theme }) => theme.textboxbackground} 0% 0% no-repeat padding-box;
  border-radius: 6px;
  text-align: center;
}
.calendar-date , .av-date{
  text-align: center;
  font: normal normal normal 18px/25px Montserrat;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.text} !important;
  margin: 3% 10% 0;
  display: grid;
  grid-template-columns: repeat(1 , 1fr 1fr 5fr 1fr 1fr);
  grid-auto-flow: column;
  align-items: center;
}
.calendar-date p ,.av-date p{
  margin: 0;
}
.calendar-date p span:first-child{
  margin-right: 1rem;
}
.calendar-date span.anticon, .av-date span{
  font:normal normal 300 12px/25px Montserrat;
}
.av-title{
  text-align: center;
  font: normal normal 600 25px/30px Montserrat !important;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin: 0 !important;
  padding-top: 36px;
}
.qpass-tour{
  width:60%;
  margin-left: auto;
  margin-right: auto;
}
.dont-show{
  text-align: center;
  font: normal normal normal 12px/20px Montserrat;
  letter-spacing: 0px;
  color: #ACB2BC !important;
}
.main-btn{
  text-align: center;
  font: normal normal 500 14px/28px Montserrat;
  letter-spacing: 0px;
  color: #FFFFFF !important;
  background: #00AC40 0% 0% no-repeat padding-box !important;
  border-radius: 3px;
  width: 158px;
  height: 39px !important;
}
.main-btn:hover{
  font: normal normal bold 16px/28px Montserrat;
}
.sec-btn.hv:hover{
  font: normal normal bold 16px/28px Montserrat;
}
.sec-btn{
  text-align: center;
  font: normal normal 500 14px/28px Montserrat;
  letter-spacing: 0px;
  color: #00AC40 !important;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border: 1px solid #00AC40 !important;
  border-radius: 3px;
  width: 158px;
  height: 39px !important;
}
.full-tour-action{
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 18px;
}
.space-tour-action{
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 12px;
}
.tour-main-title{
  background: #00AC40 0% 0% no-repeat padding-box;
  text-align: center;
  font: normal normal 600 35px/50px Montserrat;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin: 0;
  padding: 1rem;
}
.tour-main-body{
  background: #fafafa 0% 0% no-repeat padding-box;
  text-align: center; 
  padding-bottom: 20px;
  min-height: 350px;
  border:1.5px solid #c9c9c9
}
.tour-sub-title{
  font: normal normal 600 25px/30px Montserrat;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.tourColor};
  padding-top: 30px;
  margin-bottom: 26px;
}
.tour-sub-desc{
  font: normal normal normal 12px/20px Montserrat;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.tourColor};
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 26px;
  margin-top: 0;
  min-height: 40px;
}
.share-btn{
  color:#fff;
  background: #00AC40 0% 0% no-repeat padding-box !important;
  border-radius: 6px !important;
  border: 0 !important;
  font: normal normal 500 16px/28px Montserrat;
  position: relative !important;
  left: 2rem;
  top: 4rem;
}
.share-btn:hover{
  background: #fff 0% 0% no-repeat padding-box !important;

}
.album-header{
  display: grid;
  grid-template-columns: repeat(1, 8fr 1fr);
  column-gap: 0;
  row-gap: 0;
}
.album-s-title{
  text-align: left;
font: normal normal 600 35px/50px Montserrat;
letter-spacing: 0px;
color: #FFFFFF;
margin: 0 0 10px 0;
}
.album-s-desc{
  text-align: left;
font: normal normal 300 16px/26px Montserrat;
letter-spacing: 0px;
color: #8891A0;
margin: 0 0 50px 0;
}
.album-title{
  text-align: left;
  font: normal normal 600 16px/26px Montserrat;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin: 20px 0 0 0 ;
}
.album-desc{
  text-align: left;
  font: normal normal 300 14px/26px Montserrat;
  letter-spacing: 0px;
  color: #8891A0;
  margin: 0;
}
.state-title{
  text-align: left;
  font: normal normal 500 18px/25px Montserrat;
  
  letter-spacing: 0px;
  color: ${({ theme }) => theme.text};
}
.state-title p{
  margin: 0;
}

.states{
  width: fit-content;
  display: grid;
  grid-auto-flow: column;
  text-align: left;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.text};
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(1, 20px 1fr 20px 1fr 20px 1fr);
}
.states-title{
  font: normal normal normal 14px/25px Montserrat;
}
.enrolled-count{
  text-align: left;
  font: normal normal 500 25px/25px Montserrat;
  letter-spacing: 0px;
  color: #669EED;
  margin:0;
}
.attended-count{
  text-align: left;
  font: normal normal 500 25px/25px Montserrat;
  letter-spacing: 0px;
  color: #00AC40;
  margin:0;
}
.missed-count{
  text-align: left;
  font: normal normal 500 25px/25px Montserrat;
  letter-spacing: 0px;
  color: #FF684D;
  margin:0;
}
.qpass-header{
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: repeat(1, 5fr max-content 1fr);
  column-gap: 10px;
  row-gap: 0;
  align-items: center;
}
.emptydata{
  width: 65%;
  margin: auto;
}
.qpass-body{
  // display: grid;
  justify-content: space-between;
  // grid-auto-flow: column;
  // row-gap: 1rem;
  gap:10rem;
  margin-bottom: 20px;
}
.learning-body{
  //display: grid;
  justify-content: space-between;
  //grid-auto-flow: column;
  // row-gap: 1rem;
 
}
.wrapper {
  /* display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  row-gap: 1em;
  grid-auto-rows: 435px; */
  justify-content:space-between;
  column-gap: 1rem;
}
.gallery-slider-modal{
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  height: 100%;
  justify-content: space-around;
  flex-direction: column;
}
.gallery-slider-content{
  width: 100%;
}
/* .wrapper-album{
  
  justify-content:space-between;
  column-gap: 1rem;
} */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #3D444C; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3D444C; 
}
.ant-layout-header .ant-menu,
.ant-layout-header {
  background: none !important;
  border: 0;
}
.ant-modal-content  ,.ant-modal-footer{
  background-color: #111 !important;
  border: 0 !important;
}
.ant-modal-header{
  display: none !important;
}
.ant-modal-close{
  color: ${({ theme }) => theme.text} !important;
}

.ant-tag-green{
  color: #6abe39;
  background: #162312;
  border-color: #274916;
}
.ant-tag-volcano {
  color: #e87040;
  background: #2b1611;
  border-color: #592716;
}
.ant-table-thead > tr > th{
  color: ${({ theme }) => theme.tabletext} !important;
  background: ${({ theme }) => theme.tableth} !important;
}
.profile{
  border-radius:50%
}
tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td{
  
  background: #191919 !important;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table, .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table{
  margin:0;
}
.ant-table ,.ant-table-row-expand-icon{
  color: ${({ theme }) => theme.tabletext} !important;
  background: ${({ theme }) => theme.tablebody} !important;
}
.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover{
  color: ${({ theme }) => theme.tabletexth} !important;
  background: ${({ theme }) => theme.tableth} !important;
}
.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  color: rgba(255, 255, 255, 0.3);
  border-color: #434343;
  cursor: not-allowed;
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link{
  color:${({ theme }) => theme.text} ;
}
.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:focus-visible .ant-pagination-item-link{
  color:${({ theme }) => theme.paginationdisabledlink}
}
.ant-pagination-item a{
  color:${({ theme }) => theme.text} !important;
}
.ant-pagination-item-active a{
  color:#00AC40 !important;
}
.ant-pagination-item {
  display: inline-block;
  min-width: 28px;
  margin-right: 8px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: transparent;
  border: 1px solid #434343;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  user-select: none;
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #434343;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
}
.ant-pagination-item-active{
  background: transparent;
    border-color: #00AC40;
    font-weight: 500;
}
.anticon-shopping-cart{
  color: ${({ theme }) => theme.text} !important;
}
.anticon-close{
  font-size: 30px;
}
.all-videos{
  max-height: 70vh;
  overflow: auto;
  scroll-behavior: auto;
}
.video-row{
  margin-bottom: 30px;
}
.video-preview{
  width: 215px;
  height: 112px;
  margin-right: 16px;
  box-shadow: 0px 10px 20px #00000066;
  border-radius: 6px;
}
.video-title{
  text-align: left;
  font: normal normal 600 16px/26px Montserrat;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.text};
  margin:5px 0 0 ;
}
.video-desc{
  text-align: left;
  font: normal normal 300 14px/26px Montserrat;
  letter-spacing: 0px;
  color: #8891A0;
  margin:0;
}
.separate{
  color: #FFFFFF;
  font: normal normal 600 20px/20px Montserrat;
}
.watch-date{
  text-align: left;
  font: normal normal 300 14px/26px Montserrat;
  letter-spacing: 0px;
  color: #8891A0;
}
.video-status{
  background: #3D444C 0% 0% no-repeat padding-box;
  border-radius: 2px;
  text-align: left;
  font: normal normal 300 9px/26px Montserrat;
  letter-spacing: 0px;
  color: #FFFFFF;
  padding: 0 4%;
  width: fit-content;
}
.subtitle-main-v{
  text-align: justify;
  margin-top: 1rem;
  /* text-align: left; */
  font: normal normal normal 16px/26px Montserrat;
  letter-spacing: 0px;
  color: #868E96;
  width: 100%;
  /* 328px; */
}
.subtitle-main{
  text-align: justify;
  margin-top: 1rem;
  /* text-align: left; */
  font: normal normal normal 16px/26px Montserrat;
  letter-spacing: 0px;
  color: #868E96;
  width: 100%;
  /* 328px; */
}
.subtitle-main-highlight{
  text-align: justify;
  margin: 30px 0;
  /* text-align: left; */
  font: normal normal normal 16px/26px Montserrat;
  letter-spacing: 0px;
  color: #00AC40;
  font-style: italic;
  width: 100%;
}
.emp-col{
  padding: 0 ;
}
.v-preview{
  width: 100%;
  border-radius: 6px;
}
.v-preview:hover{
  transform: scale(1.5);
}

.v-slide-cover{
  background-size: cover;
}
.v-slide-cover:hover{
  background-size: inherit;
}
.v-icon{
  position: absolute;
  left: calc(50% - 31px/2);
  top: calc(50% - 38px);
  width: 31px;
  height: 31px;
}
.file-icon{
  width: auto;
  height: 300px;
}
.v-icon:hover{
  position: absolute;
  transform: scale(1.5);
}
.emp-guide-content{
  /* display: grid !important;
  grid-auto-flow: column; */
  justify-content: center;
  align-items:center;
  min-height: 50vh;
  /* grid-template-columns: 1fr 1fr; */
}
.f-title{
  text-align: left;
  font: normal normal normal 14px/28px Montserrat;
  letter-spacing: 0px;
  color:${({ theme }) => theme.text};
  margin-top: 2rem !important;
  margin-bottom: 0;
  // margin-left: 15px;
}
.v-title{
  text-align: left;
  font: normal normal normal 14px/28px Montserrat;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin-top: 10px !important;
  margin-bottom: 0;
}
.emp-slider{
  width: 100%;
  display: flex;
  margin: 64px 0 0 0;
  justify-content: space-around;
}
.emp-slider-row{
  column-gap: 16px;
  justify-content: space-between;
  width: 100%;
  min-height: 136px;
}
.emp-row{
  margin: 64px -4px 0 !important;
  row-gap: 7px !important;
}
.emp-function{
  background: #3D444C 0% 0% no-repeat padding-box;
  border-radius: 3px;
  text-align: center;
  font: normal normal 500 14px/39px Montserrat;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin:0 4px;
  height: 39px;
}
.emp-function:hover{
  background: none;
  border-radius: 3px;
  border-bottom: solid 2px #00AC40;
}
.emp-function.active{
  background: #00AC40 0% 0% no-repeat padding-box;
  border-radius: 3px;
  text-align: center;
  font: normal normal 500 14px/39px Montserrat;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin:0 4px;
  height: 39px;
}

.emp-modules{
  background: #3D444C 0% 0% no-repeat padding-box;
  border-radius: 3px;
  text-align: center;
  font: normal normal 500 14px/39px Montserrat;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin:0 4px;
  height: 39px;
}
.emp-modules:hover{
  background: none;
  border-radius: 3px;
  border-bottom: solid 2px #00AC40;
}
.emp-modules.active{
  background: #00AC40 0% 0% no-repeat padding-box;
  border-radius: 3px;
  text-align: center;
  font: normal normal 500 14px/39px Montserrat;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin:0 4px;
  height: 39px;
}

.emp-subtitle{
  margin-top: 75px;
  text-align: left;
  font: normal normal 600 14px/26px Montserrat;
  letter-spacing: 0px;
  color: #FFFFFF;
  width: fit-content;
  border-bottom: #00AC40 2px solid;
}

.thank-box{
  background: #fff;
  padding: 2rem 0;
  display: grid;
  justify-items: center;
}
.thanku-home{
  background: #00AC40 0% 0% no-repeat padding-box !important;
  border-radius: 6px !important;
  width: 219px;
  height: 50px !important;
  color: #FFFFFF !important;
  border: 0 !important;
  text-align: center;
  font: normal normal 500 16px/28px Montserrat !important;
  margin-bottom: 30px;
}
.thanku{
  text-align: center;
  font: normal normal 600 25px/30px Montserrat !important;
  color: #212529 !important;
}
.thank-desc{
  text-align: justify;
  font: normal normal normal 14px/26px Montserrat;
  letter-spacing: 0px;
  color: #5A6069;
}
.thanku-content{
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.thanku-actions{
  display: grid;
  grid-auto-flow: column;
  gap: 12px;
  width: 70%;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon{
  display: none !important;
}
.team-avatar{
  width: 100%;
  border-radius: 0px;
  z-index: 0;
  /* filter: grayscale(80%) contrast(100%); */
}
.team-info{
  z-index: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  padding-bottom: 12px;
  bottom: 0;
  width: 100% !important;
  height: 100%;
  /* background : rgba(0, 0, 0, 0.5); */
  background: transparent linear-gradient(180deg, #131517CC 0%, #131517CC 0%, #00000000 0%, #00190924 56%, #00AC40 100%) 0% 0% no-repeat padding-box;
}
.team-info p.name{
  margin: 0;
  text-align: center;
  color: #FFFFFF;
  font: normal normal 500 13px/30px Montserrat;
}
.team-info p.desc{
  margin: 0;
  text-align: center;
  color: #FFFFFF;
  font: normal normal 300 9px/10px Montserrat;
}

.gutter-row div{
  width: fit-content;
  display: contents;
}
.product div.counter{
  grid-auto-flow: column;
  display: grid;
  align-items: baseline;
  gap: 10px;
}
.product div.counter p{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #212529;
  border-radius: 6px;
  text-align: center;
  font: normal normal normal 14px/26px Montserrat;
  letter-spacing: 0px;
  color: #212529;
  padding: 4px 9px;
}
.product div.counter span{
  font: normal normal normal 14px/26px Montserrat;

}
.product-img{
  border-radius: 6px;
  width: 100%;
  height: auto;
}
.product-info{
  display: grid;
  width: 100%;
  margin: 1rem 0 3rem;
  /* background : rgba(0, 0, 0, 0.5); */
}
.product-info p.name{
  margin: 0;
  text-align: left;
  font: normal normal normal 14px/26px Montserrat;
  color: #868E96;
}
.product-info p.desc{
  margin: 0;
  text-align: left;
  font: normal normal 600 16px/26px Montserrat;
  color: ${({ theme }) => theme.text};
}
.qty{
  margin: 0;
}
 .ant-row.ant-form-item > .ant-form-item-label > label{
  color :${({ theme }) => theme.text}
}
.refill{
  cursor: pointer;
  margin: 0;
  border: 1px solid;
  text-align: center;
  color: lightblue;
}
.refill-box{
  padding: 5% 0 !important;

  background: ${({ theme }) => theme.body};
  width: 100%;
}
.history-box{
  padding: 5% !important;
  background:${({ theme }) => theme.body} ;
  width: 100%;
}
.refill-form{
  width: 80%;
  justify-content: space-around;
  margin-left: auto !important;
  margin-right: auto !important;
}
.site-layout-content {
  min-height: 550px;
  padding: 24px 24px 0;
}
.ant-pagination-simple .ant-pagination-simple-pager{
  display: none!important;
}
.gutter-row{
  padding: 0 !important;
  margin: 1%;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu{
  padding: 0 !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  color: ${({ theme }) => theme.text} !important;
  background-color:  ${({ theme }) => theme.body} !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected  , .ant-menu-horizontal > .ant-menu-item a,.ant-menu-horizontal > .ant-menu-item-selected a
{
  color: ${({ theme }) => theme.text} !important;
}
#header-search{
  display:none;
  color: ${({ theme }) => theme.text} !important;
}
.ant-menu-horizontal > .ant-menu-item::after, .ant-menu-horizontal > .ant-menu-submenu::after {
  right: 0 !important;
  left: 0 !important;
}

.ant-menu-horizontal > .ant-menu-item span, .ant-menu-horizontal > .ant-menu-item a{
  display: block;
}
.logo {
  float: left;
  width: auto;
  height: 32px;
  margin: 0;
  background: none;
  display: flex;
}
.ant-layout-content{
  padding: 0 24px 0 !important;

}
.ant-layout-header {
  margin: 0 0 2%;
  padding: 24px 24px 0 !important;
  display: flex!important;
  justify-content: space-between;
  height: fit-content !important;
}
.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

  
#root{
  /* padding: 72px 94px 57px 94px; */
  height: 100%;
  padding: 2% 4% 2% 4%;
}

.footer-swich{
  width: 25px !important;
  height: 12px !important;
  min-width: 25px !important;
  background-color: ${({ theme }) => theme.body} !important;
  border: 2px solid ${({ theme }) => theme.text} !important;
  border-radius: 6px !important;
}
button.ant-switch.footer-swich.ant-switch-checked{
  background-color: ${({ theme }) => theme.body} !important;
  border: 2px solid ${({ theme }) => theme.text} !important;
  border-radius: 6px !important;
}
.ant-switch-handle{
  top: 1px !important;
}
.ant-switch-checked .ant-switch-handle{
  left: calc(100% - 7px) !important;
}
.ant-switch-handle::before{
width: 6px;
height: 6px;
background-color:${({ theme }) => theme.text}
}
.qritem span{
  font-size: 32px!important;
}
li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child.qritem a:hover , li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child.qritem:hover::after , li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child.qritem::after , li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child.qritem a{
  border-bottom: 0!important;
  color: #00AC40 !important;
}
.ant-menu-item a{
  color: ${({ theme }) => theme.text};
}
.ant-menu-item-selected a{
  color :#00AC40 ;
}
.ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow{
  color: ${({ theme }) => theme.text};
}
li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child.search-icon {
  color: ${({ theme }) => theme.text};
}
.ant-menu-inline.ant-menu-root .ant-menu-item, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title{
  color: ${({ theme }) => theme.text} !important;
  background: ${({ theme }) => theme.body} !important;

}
.anticon-caret-right{
  color:#fff
}
li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child.search-icon:hover , li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child.search-icon:hover::after , li.ant-menu-overflow-item.ant-menu-item-selected.ant-menu-item-only-child.search-icon:hover{
  border-bottom: 0!important;
  color: #00AC40 !important;
}
.ant-layout-header .ant-menu a{
  line-height: 16px!important;
  font: normal normal 600 16px/26px Montserrat !important;
}
.wq-page{
  justify-content: space-between;
  margin-top:2rem;
}
.cont-row{
  align-items: center;
  justify-content: space-between;
}

.cont-col-connect{
  min-height:480px!important;
  width:100%;
  margin: 0 0.5%;
  background-size: cover;
  border-radius: 6px;
}
.cont-col-connect:hover{
  min-height:480px!important;
  width:100%;
  margin: 0 0.5%;
  background-size: cover;
  border-radius: 6px;
}

#qrCodeElToRender{
  margin:0 calc(50% - (250px/2))
}
.cont-col-learn{
  min-height:480px!important;
  width:100%;

  margin: 0 0.5%;
  background-size: cover;
  background-image: url("/src/media/learn.png");
}
.cont-col-see{
  min-height:480px!important;
  width:100%;

  margin: 0 0.5%;
  background-size: cover;
  background-image: url("/src/media/see.png");
}
.cont-col-text{
  margin-right: 0.5rem;
}
h1.title-main{
  font: normal normal 600 35px/53px Montserrat;
}
p.desc-main-v{
  font: normal normal normal 12px/22px Montserrat;
  color: #868E96;
  display: block;/* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 13em;
  line-height: 1.9em;
  max-width: 100%;
  margin: 0 0 0.5rem;
  text-align: justify;
}
p.desc-main{
  
  font: normal normal normal 14px/26px Montserrat;
  color: #868E96;
  display: block;/* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 13em;
  line-height: 1.9em;
  max-width: 100%;
  margin: 0 0 0.5rem;
  text-align: justify;
}

p.desc-main-contact-us{
  margin-bottom: 0;
  
  font: normal normal normal 14px/26px Montserrat;
  color: #868E96;
}
div.desc-main-contact-us{
  margin-top: 30px;
}
.desc-list{
    display: flex;
    margin-bottom: 20px;
    align-items: flex-end;
    justify-content: flex-start;
}
.desc-list span{
  margin-left: 17px;
  font: normal normal normal 14px/19px Montserrat;
  color: #868E96;
}
.contact-us-row{
    margin: 8% 0 5vh;
  justify-content: space-between;
}
.left-contact-us ,.left-video{
  padding-left: 1.5rem;
}
.left-contact-us h1{
  text-align: left;
  font: normal normal 600 13px/26px Montserrat;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.text} !important;
  opacity: 1;
  border-bottom: 2px solid #00AC40;
  width: fit-content;
}
.ant-input:hover {
  border-color: #077731;
  border-right-width: 1px !important;
}
.ant-input:focus, .ant-input-focused,ant-select-focused {
  border-color: #00AC40;
  box-shadow: 0 0 0 2px rgb(42 131 20 / 20%);
  border-right-width: 1px !important;
  outline: 0;
}
.ant-result-subtitle,.ant-result-title{
  color:${({ theme }) => theme.text};

}

.left-contact-us >.ant-form >.ant-row>.ant-col>label{
width:100%

}

.left-contact-us >.ant-form >.ant-row>.ant-col>.ant-form-item-control-input>.ant-form-item-control-input-content>.ant-input-affix-wrapper{
  border-color:${({ theme }) => theme.textboxbackground};
  background: ${({ theme }) => theme.textboxbackground} 0% 0% no-repeat padding-box;
  border-radius: 6px;
}
.left-contact-us >.ant-form >.ant-row>.ant-col>.ant-form-item-control-input>.ant-form-item-control-input-content>.ant-input-affix-wrapper:hover
,.left-contact-us >.ant-form >.ant-row>.ant-col>.ant-form-item-control-input>.ant-form-item-control-input-content>.ant-input-affix-wrapper:focus{
  border-color:#00AC40 ;
  background: ${({ theme }) => theme.textboxbackground} 0% 0% no-repeat padding-box;
  border-radius: 6px;
  box-shadow: 0 0 0 2px #00ac404f;
}
.left-contact-us textarea {

  background: ${({ theme }) => theme.textboxbackground} 0% 0% no-repeat padding-box;
  border-radius: 6px;
  border-color:${({ theme }) => theme.textboxbackground};
  min-height: 218px!important;
  max-height: 218px!important;
  height: 218px!important;
}
.contact-us-submit{
  min-height: 39px;
  float: right;
  min-width: 118px;
  margin-top: 20px;
  background: #00AC40 0% 0% no-repeat padding-box !important;
  border-radius: 6px !important;
  text-align: center !important;
  border: 1px solid #00AC40 !important;
  font: normal normal normal 12px/28px Montserrat !important;
  color: #FFFFFF;
}


.contact-us-submit:hover , .contact-us-submit:focus{
  min-height: 39px;
  float: right;
  min-width: 118px;
  margin-top: 20px;
  background: #3D444C 0% 0% no-repeat padding-box !important;
  border-radius: 6px !important;
  text-align: center;
  font: normal normal 500 12px/28px Montserrat !important;
  letter-spacing: 0px;
  color: #FFFFFF!important;
}
.ant-layout-footer {
  bottom: 0;
  height: auto;
  padding: 0 !important;
  background: none !important;
}
p.title-sub{
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 10%;
  font: normal normal 600 25px/38px Montserrat;
  color: #fff;

}

.hide{
  display: none;
}
.cont-content{
  border-radius: 6px;
  padding: 15% 5% 0;
  height: 480px;
  background: transparent linear-gradient(360deg, #131517CC 0%, #131517CC 0%, #00000000 0%, #131517CC 0%, #131517B3 56%, #00AC40 100%) 0% 0% no-repeat padding-box;
}
.cont-content p.title{
  width: 100%;
  margin-bottom: 40px;
  text-align: center;
  font: normal normal 600 25px/38px Montserrat;
  color: #fff;
}
.cont-content p.desc{
  color: #fff;
  width: 100%;
  margin-bottom: 40px;
  text-align: left;
  font: normal normal normal 18px/25px Montserrat;
}
.cont-content ul li {
  width: 100%;
  text-align: left;
  margin-bottom: 25px;
  list-style-type: none;
  font: normal normal 500 18px/25px Montserrat;
  display: inline-flex;
  justify-content: space-between;
    align-items: center;
}
.cont-content ul li a{
 color: #FFFFFF;
 width: fit-content;
 text-decoration: underline;
}
ul.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light{
  width: 560px;
  height: fit-content;
  align-items: center;
  justify-content: space-between !important;
}
.cont-content ul li a:hover ,.cont-content ul li:hover {
  font: normal normal 600 18px/25px Montserrat;
 }

 .cont-content ul{
   padding:0;
 }
 .cart{
   margin-top: 20%;
   padding: 0 5% 5%;
 }
 .cart-products{
   margin-bottom: 50px;
 }
 .cart p.title{
  text-align: left;
  font: normal normal 600 25px/30px Montserrat;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.text};
  opacity: 1;
 }
 .empty-card{
   width: 100%;
 }
 .empty-card img{
   width: 80%;
   margin-left: 10%;
   margin-right: 10%;
 }
 .empty-card p{
   text-align: center;
 }
 .confirm-actions{
  display: grid;
  grid-auto-flow: column;
  gap: 12px;
  width: 80%;
  margin: auto;
  margin-bottom: 20px;
 }
 .payment-actions{
  display: grid;
  grid-auto-flow: column;
  gap: 12px;
 }
 .qr-container__form{
   width: 100%;
   display: grid;
   grid-auto-flow:column;
   column-gap:0.5rem
 }

 button.ant-btn.proceed[disabled] , button.ant-btn.proceed[disabled]:hover {
  background: #3D444C;
  color: #868E96;
  font: normal normal 500 16px/28px Montserrat;
 }
 button.ant-btn.proceed:hover {
  font: normal normal 600 18px/28px Montserrat;
 }
 button.ant-btn.cancel:hover {
  font: normal normal bold 18px/28px Montserrat;
 }
 
 button.ant-btn.confirm-cancel{
    height: 28px;
   text-align: center;
   font: normal normal 500 8px/20px Montserrat;
   letter-spacing: 0px;
   color: #00AC40;
   background: #FFFFFF 0% 0% no-repeat padding-box;
   border: 1px solid #00AC40;
   border-radius: 6px;
  }
  button.ant-btn.confirm-ok{
    height: 28px;
   text-align: center;
   font: normal normal 500 8px/20px Montserrat;
   letter-spacing: 0px;
   color: #FFFFFF;
   background: #00AC40 0% 0% no-repeat padding-box;
   border-radius: 6px;
  }
.confirm-message{
  font: normal normal 500 16px/28px Montserrat;
  color: #3D444C;
}

 button.ant-btn.cancel{
 height: 58px;
  text-align: center;
  font: normal normal 500 16px/28px Montserrat;
  letter-spacing: 0px;
  color: #00AC40;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #00AC40;
  border-radius: 6px;
 }
 button.ant-btn.proceed{
 height: 58px;
  text-align: center;
  font: normal normal 500 16px/28px Montserrat;
  letter-spacing: 0px;
  color: #FFFFFF;
  background: #00AC40 0% 0% no-repeat padding-box;
  border-radius: 6px;
  border: 0;
 }
 .payment-details{
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  text-align: right;
  font: normal normal normal 16px/26px Montserrat;
  letter-spacing: 0px;
  color: #868E96;
  opacity: 1;
 }
 
 .payment-details-mypoints{
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  text-align: right;
  font: normal normal normal 16px/26px Montserrat;
  letter-spacing: 0px;
  color: #00AC40;
  opacity: 1;
 }
 .payment-details-balance{
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  text-align: left;
  font: normal normal 600 14px/26px Montserrat;
  letter-spacing: 0px;
  color: #868E96;
  margin-bottom: 1rem;
 }
 .payment-details-balance-error{
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  text-align: right;
  color: red;
  font: normal normal 600 14px/26px Montserrat;
  letter-spacing: 0px;
  margin-bottom: 1rem;
 }
 .product {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns:repeat(1,1fr 3fr 1fr 1fr);
  justify-content: start;
  align-items: center;
  margin-bottom: 25px;
 }
 .product p.name{
  text-align: left;
  font: normal normal normal 14px/26px Montserrat;
  letter-spacing: 0px;
  color: #868E96;
 }
 .product p.price{
  text-align: right;
  font: normal normal 600 16px/26px Montserrat;
  letter-spacing: 0px;
  color: #868E96;
  width: 70px;
 }
 .product img.product-img{
   width: 130px;
   height: 130px;
   border: 1px solid #868E96;
   border-radius: 6px;
   margin-right: 27px;
 }
 .ant-drawer-close{
   color: ${({ theme }) => theme.text}  !important;
   font-size: 21px !important;
   margin-right: 1rem !important;
   margin-top: 1rem;
   padding: 0 !important;
 }
.ant-drawer-content{
  background: ${({ theme }) => theme.body} !important;
  font: normal normal 600 25px/30px Montserrat;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.text} !important;
 }
 .claim-reward-col{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.history{
  margin: 0 1.2rem;
  cursor: pointer;
}
.claim-reward-col div.shopping{
/* font-size: 38px; */
min-width: 60px;
}
.claim-reward-col div.points
{
  max-width: 193px;
  margin: 0;
  background: #3D444C 0% 0% no-repeat padding-box;
  border-radius: 6px;
  padding: 18px 0;
}
.product-view{
  width: 100%;
  height: auto;
}
.product-unavailable{
  background: #13151790 0% 0% no-repeat padding-box; 
  width: 100%;
  height: calc(100% - 50px - 4rem );
  border-radius: 0px 0px 6px 6px;
  position: absolute;
}

.add-cart-available , .add-cart-unavailable ,.add-cart-unavailable-added ,.add-cart-unavailable-out{
  width: 100%;
  height: calc(100% - 50px - 4rem );
  position: absolute;
  background: transparent linear-gradient(180deg, #00000000 0%, #0E0F114D 28%, #212529 100%) 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 6px 6px;
}
.add-cart-unavailable-out button.ant-btn.add-to-cart{
  position: absolute !important;
  top: calc((58px + 50%)/2);
  width: 100%;
  height: 58px !important;
  text-align: center !important;
  color: red !important;
  background-color: #FFFFFF !important;
  border-radius: 6px !important;
  border:0 !important;
  font: normal normal 600 16px/28px Montserrat;
  left: 0;
} 
.add-cart-unavailable-added button.ant-btn.add-to-cart{
  position: absolute !important;
  top: calc((58px + 50%)/2);
  width: 100%;
  height: 58px !important;
  text-align: center !important;
  color: #00AC40 !important;
  background-color: #FFFFFF !important;
  border-radius: 6px !important;
  border:0 !important;
  font: normal normal 600 16px/28px Montserrat;
  left: 0;
} 
.add-cart-unavailable button.ant-btn.add-to-cart{
  position: absolute !important;
  top: calc((58px + 50%)/2);
  width: 100%;
  height: 58px !important;
  text-align: center !important;
  color: #FFFFFF !important;
  background-color: #3D444C !important;
  border-radius: 6px !important;
  border:0 !important;
  font: normal normal 600 14px/28px Montserrat;
  left: 0;
}
.add-cart-available button.ant-btn.add-to-cart{
  position: absolute !important;
  top: calc((58px + 50%)/2);
  width: 100%;
  height: 58px !important;
  text-align: center !important;
  color: #FFFFFF !important;
  background-color: #00AC40 !important;
  border-radius: 6px !important;
  border:0 !important;
  font: normal normal 600 16px/28px Montserrat;
  left: 0;
}
.add-cart-available button.ant-btn.add-to-cart:hover{
  background: #00AC40 0% 0% no-repeat padding-box;
border-radius: 6px;
font: normal normal bold 18px/28px Montserrat;
}
.claim-products{
  /* justify-content: space-between;
  row-gap: 5rem !important; */
  /* display: grid !important;
  grid-auto-flow: column; */
  /* grid-template-rows: 380px 38em max-content auto; */
  /* grid-column-gap: 6%; */
  margin-top: 5rem;
  justify-content: space-between !important;
  column-gap: 1rem;
}
.ant-badge-count{
  background:#00AC40 !important;
}
.claim-reward-main , .claim-reward-main h1.title-main , .claim-reward-main p.desc-main-contact-us {
  width: 100%;
  max-width: 100%;
}
.claim-reward-col div.points p{
  margin:0 ;
  max-width: 100%;
  text-align: center;
  font: normal normal 600 16px/26px Montserrat;
  color: #FFFFFF;
  min-width: 162px;
}
.claim-reward-col div.points p.rewords-title{
  font: normal normal 500 8px/10px Montserrat!important;
}
.claim-reward-col div.points p.rewords-points{
 font: normal normal 600 45px/48px Montserrat;
}

.qp-col{
  max-width: 250px;
  width: 250px;
}
.qp-col p{
 margin: 2% 0 0;
 text-align: left;
 font: normal normal 600 16px/26px Montserrat;
 color: ${({ theme }) => theme.text} !important;
 max-width: 100%;
 min-width: 162px;
}

.qp-col div{
  margin: 4% 0;
  background: ${({ theme }) => theme.textboxbackground} 0% 0% no-repeat padding-box;
  border-radius: 6px;
  padding: 18px 0;
 }
.qp-col div p{
  margin:0 ;
  max-width: 100%;
  text-align: center;
  color: #FFFFFF;
}
.qp-col div p.rewords-title{
   font: normal normal 500 8px/10px Montserrat!important;
 }
 .qp-col div p.rewords-points{
  font: normal normal 600 45px/48px Montserrat;
 }
 .qp-col button {
   border: 0;
  background: #00AC40 0% 0% no-repeat padding-box;
  border-radius: 6px;
  text-align: center;
  
  font: normal normal normal 22px/43px Montserrat;
  letter-spacing: 0px;
  color: #FFFFFF;
  min-height: 45px;
  min-width: 100%;
 }
 .qp-col button:hover{
  background: #3D444C 0% 0% no-repeat padding-box;
  border: 1px solid #00AC40;
  border-radius: 6px;
  text-align: center;
  font: normal normal normal 22px/41px Montserrat;
  letter-spacing: 0px;
  color: #FFFFFF;
  min-height: 45px;
  min-width: 100%;
 }
 .qr-calendar{
  background: #3D444C 0% 0% no-repeat padding-box;
  border-radius: 6px;
  float: right;
 }
 .site-calendar-demo-card {
  width: 300px;
}
.ant-picker-calendar{
  background: none!important;
}


.ant-carousel .slick-list .slick-slide > div > div {
  width: 100% ;
  display: grid !important;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  column-gap:1rem
  
  /* justify-content: space-between; */
}
.ant-carousel .slick-dots-bottom{
  bottom: -60px !important;
  height: 12px;
  align-items: flex-end;
}
.ant-carousel .slick-dots li.slick-active button{
  background: #00AC40 !important;
  height: 10px !important;
  border-radius: 50%;
  align-items: center;
}
.ant-carousel .slick-dots li button{
  background: #fff !important;
  height: 10px !important;
  border-radius: 50%;
  align-items: center;
}
.ant-carousel .slick-dots li{
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
  background: ${({ theme }) => theme.text}  0% 0% no-repeat padding-box;
}
.contentStyle{
  width:100%;
  height: auto;
  text-align: center;
  margin:auto 0;
  // display: inline !important;
  border-radius: 6px;
}
.team-all{
  display: grid !important;
  grid-auto-flow: column;
  grid-template-columns: repeat(3,1fr);
  
  column-gap: 1rem;
}
.team-view{
  display: grid !important;
  grid-auto-flow: column;
  column-gap: 1rem;
  grid-template-columns: 38% 59%;
  justify-content: space-between;
}
.filter-imp{
  display: grid;
  justify-items: start;
  column-gap: 1rem;
  margin-top: 30px;
  text-align: left;
  font: normal normal normal 14px/26px Montserrat;
  letter-spacing: 0px;
  color: #868E96;
}
.filter-team{
  grid-auto-flow: column;
  justify-items: end;
  justify-content:end;
  display: grid;
  column-gap: 1rem;
  margin-top: 30px;
  text-align: left;
  font: normal normal normal 14px/26px Montserrat;
  letter-spacing: 0px;
  color: #868E96;
}
.filter-erp{
  column-gap: 1rem;
  margin-top: 30px;
  text-align: left;
  font: normal normal normal 14px/26px Montserrat;
  letter-spacing: 0px;
  color: #868E96;
}
.ant-breadcrumb-separator{
  color: #00AC40 !important;
}
span.anticon.anticon-caret-left {
  margin-right: 12px;
}
.ant-breadcrumb{
  text-align: left;
  font: normal normal 600 12px/26px Montserrat;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.text} !important;
  cursor: pointer;
}
.ant-breadcrumb > span:last-child{
  border-bottom: 2px #00AC40 solid;
  color: ${({ theme }) => theme.text} !important;
}
.ant-breadcrumb > span:not(:last-child):hover{
  color: #00AC40;
  /* display: contents;
  text-decoration: underline; */
}
span.anticon.anticon-caret-left:hover{
  color: #00AC40;
}
.main-desc-div{
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1.5rem 1fr;
}
.filter-erp p{
  margin: 1px 0;
}
.filter-erp p:hover 
{
  font-weight: bold;
  color: #fff;
}
.filter-erp p.filter-title{
  color: #FFFFFF;
}
.ant-select-dropdown ,.ant-select-item ,.ant-select-arrow{
  color: ${({ theme }) => theme.text} !important;
  background-color:${({ theme }) => theme.body} !important;
}

.ant-select-item:hover ,.ant-select-item-option-active{
  background-color:#00AC40 !important;
}
.filter-team p.filter-title , .filter-imp p.filter-title{
  color: ${({ theme }) => theme.text} !important;
  text-align: right;
}
.filter-erp p.filter-title:hover{
  font-weight: 400;
  color: ${({ theme }) => theme.text} !important;
}
.filter-erp p.selected{
  border-bottom: 1px #00AC40 solid;
  font-weight: bold;
  color: #fff;
  width: fit-content;
}
.ant-input-affix-wrapper{
  background-color:${({ theme }) => theme.body} !important;
  color: ${({ theme }) => theme.text} !important;
}
.ant-input{
  background-color:${({ theme }) => theme.body} !important;
  color: ${({ theme }) => theme.text} !important;
}
.erp-df-slider-col{
  padding: 0 0 0 1%;
}
.erp-df-col2{
  display: flex !important;
  justify-content: space-around;
}
.erp-df-col h1.title-main{
  margin-top: 20px!important;
}
.erp-df-col p.subtitle-main{
  width: auto !important;
}
.bottom-center {
display:none;

  /* position: absolute; 
  display: block;*/
  /* padding: 0px 43px 0 10px; */
  top: 0;
  color: #FFFFFF;
  font-size: 25px;
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 0  ;
  border-radius: 6px;
  background-size: cover;
  background-repeat: no-repeat;
  
}
.bottom-center.hovered{
  //background: transparent linear-gradient(360deg, #131517CC 0%, #131517CC 0%, #00000000 0%, #131517CC 3%, #131517B3 43%, #00AC40 100%) 0% 0% no-repeat padding-box;
display:block;
min-height:480px;
}
.bottom-center p.title{
  // position: absolute;
  // bottom: 52px;
  // top:85%;
  width: 95%;
  text-align: center;
  font: normal normal 600 20px/38px Montserrat;
  letter-spacing: 0px;
  color: #FFFFFF;
  left: 2.5%;
  margin-top: -28%;
}
.slide-content p.title{
  position: inherit;
  text-align: center;
  font: normal normal 600 20px/28px Montserrat;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin: 0 0 1.5rem;
}
.slide-content{
  display: none;
  padding: 40px 5%;
}
.slide-content p.desc-t{
  white-space: pre-line;
  text-align: left;
  font: normal normal normal 1rem/1.5rem Montserrat;
  /* normal normal normal 16px/20px Montserrat; */
  //margin-bottom: 1rem;
  letter-spacing: 0px;
  color: #FFFFFF;
  padding: 0 ;
  margin-bottom:0;
}
.slide-content p.desc{
  white-space: pre-line;
  text-align: left;
  font: normal normal normal 1rem/1.5rem Montserrat;
  /* normal normal normal 16px/20px Montserrat; */

  letter-spacing: 0px;
  color: #FFFFFF;
  padding: 0 ;
  
  margin-bottom:2px;
}
.drawer-content{
  height: 100%;
  display: grid;
}
#main-menu-mobile{
  padding: 20% 5% 0;
  /* display: grid; */
}
.ant-drawer-body{
  padding: 0 !important;
}
.nav-mobile{
  display: grid;
  grid-auto-flow: column;
  justify-items: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  margin: 1rem 0;
}
.gallery-icon{
  position: relative;
  left: 80%;
  top: 80%;

}
.footer{
  display:block;
}
.slidesrow{
  row-gap:1rem !important;
}
.slide-list{
  padding-left:2rem
}
.web{
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  width: 100%;
}
.mobile{
  display:none
}
.mobile-logo{
  width:75%;
}
.mobile-icon{
  font-size: 10vw
}

.slidescarousel{
  display:block;
}
.slidesrow{
  display:none;
}
@media screen and (max-width: 1024px){
  .emptyoops{
    margin:1rem auto;
    height:auto;
    width:90%;
    
  }
  .erp-df-slider-col{
    max-width: 100% !important;
    padding: 0;
  }
  
  .contentStyle {
    width: 100% !important;
    height:auto;
  }
  .bottom-center{
    width: 26vw;
  }
}
/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .slidescarousel{
    display:none !important;
  }
  .slidesrow{
    display:block;
  }
  .available-training{
    margin-bottom:2rem;
  }
  p.desc-main-contact-us{
    max-width:100%;
  }
  .filter-team,filter-imp{
    //grid-template-columns: 1fr 2fr;
    justify-content: start;
    justify-items: start;
    grid-auto-flow: row;
  }
  .filter-team p.filter-title, .filter-imp p.filter-title{
    text-align:left
  }
  .qpass-body{
  justify-content: center;

  }
  .logo{
    height: auto !important;
  }
  .ant-menu-inline.ant-menu-root .ant-menu-item, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title{
    margin-bottom: 1rem !important;
    column-gap:1rem;
  }
  .subtitle-main{
    margin-bottom: 2rem;
  }
  .ant-menu-inline.ant-menu-root .ant-menu-item-selected > .ant-menu-title-content > a > img {
    filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(70%) contrast(119%);
  }
  .qpass-tour{
    width:100%;
    margin-bottom:2rem
  }
  .ant-menu-item-icon:hover{
    filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(70%) contrast(119%);
  }
  .ant-menu-submenu-selected >.ant-menu-submenu-title> .ant-menu-item-icon{
    filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(70%) contrast(119%);
  }
  ::-webkit-scrollbar {
    width: 0;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #3D444C; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #3D444C; 
  }
  .all-videos{
    max-height: 100%;
    overflow: hidden;
    scroll-behavior: auto;
  }
  .wrapper > .ant-col{
    margin-bottom: 2rem;
  }
  .left-contact-us{
    padding: 0;
    margin : 2rem 0;
  }
  .left-video{
    padding: 2rem 1rem 0;
    border-top: 1px solid rgba(0,0,0,0.3);
     margin: 1rem  0;
  }
  .video-viewer{
    width: 100%;
  }
  .site-layout-content{
    
    padding: 0;
  }
  .ant-layout-header {
    padding: 1% 4% !important;
    display: flex!important;
    justify-content: space-between;
    height: fit-content !important;
    margin-left: -4%;
    width: 108%;

    background: #212529 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  }
  .qpass-header{
    grid-template-columns:100%;
    row-gap: 1.5rem;
  }
  .cont-row{
    display: grid;
    row-gap: 2rem !important;
    margin-bottom: 2rem;
  }
  .erp-df-slider-col{
    padding: 16px 0;
  }
  .bottom-center{
    width: 100%;
    height:100%;
    margin-bottom:1rem;
  }
  .contentStyle{
    margin-bottom:1rem;

  }
  p.desc-main{
    width: 100%;
    margin: 2rem auto;
  }
  .main-desc-div{
    width: 100%;
    margin: auto;
  }
}
@media screen and (max-width: 800px) {{
  .web{
    display : none !important;
  }
  .mobile{
    display:block
  }
}
/* On screens that are 768px or less, set the background color to olive */
@media screen and (max-width: 768px) {
  .ant-table-thead > tr > th{
    font-size:14px;
    padding:2%;
  }
  .footer{
    display:none
  }
  .claim-reward-col{
    justify-content: space-between;
  }
  .claim-reward-col div.points{
    max-width: fit-content;
  }
  .claim-reward-col div.points p.rewords-title{
    font: normal normal 500 8px/10px Montserrat!important;
  }
  .claim-reward-col div.points p.rewords-points{
   font: normal normal 600 25px/30px Montserrat;
  }
  .claim-reward-col div.points p{
    max-width: 100%;
    min-width:50%;
    padding: 0 1rem;
  }
  .history{
    margin: 0;
  }
  
  
}
@media screen and (max-width: 450px) {
  .ant-table-thead > tr > th{
    font-size:10px;
    padding:1%;
  }
  .bottom-center.hovered{
    //background: transparent linear-gradient(360deg, #131517CC 0%, #131517CC 0%, #00000000 0%, #131517CC 3%, #131517B3 43%, #00AC40 100%) 0% 0% no-repeat padding-box;
  display:block;
  min-height:50vh;
  }
  .file-icon {
    width: 80%;
    margin: 5% 10%;
    height: auto;
}
  .slide-list{
    padding-left:5%
  }
  .bottom-center p.title{
    top:75%;
    font: normal normal 600 60%/1 Montserrat;
  }
  
  .slide-content p.desc-t{
    font: normal normal normal 50%/1.5rem Montserrat;
  }
  .slide-content p.desc{
    font: normal normal normal 50%/1 Montserrat;
  }
  .slidesrow{
    row-gap:5rem !important;
  }
  .events p{
    font: normal normal 500 12px/20px Montserrat;
  }

  .ant-picker-calendar .ant-picker-panel{
    
    padding: 1%;
  }
}
@media screen and (max-width: 332px) {
{
  .ant-layout-content{
    padding:0 !important
  }
}
  `;
