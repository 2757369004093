import { CaretLeftOutlined } from "@ant-design/icons";
import { Breadcrumb, Col, Empty, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import ReactPlayer from "react-player/lazy";
import moment from "moment";
import emptyicon from "../../media/icons/no-date-icon.png";

export default function WatchVideos() {
  const [watchData, setWatchData] = useState([
    {
      id: 4,
      title: "Quantum Video",
      url: "videos/Quantum.mp4",
      preview: "videos/thumb/Quantum.jpg",
      status: 0,
      views: 500,
      guideFunctionElementId: 1,
      guideFunctionElement: null,
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-12-07T22:00:00",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 3,
      title: "Digital Transformation Vision",
      url: "videos/test-video.mp4",
      preview: "videos/thumb/test-video.jpg",
      status: 0,
      views: 500,
      guideFunctionElementId: 1,
      guideFunctionElement: null,
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-11-01T11:45:15.627",
      u_status: "success",
      u_errormsg: "",
    },
    
    {
      id: 2,
      title: "Digital Transformation Vision",
      url: "videos/test-video-2.mp4",
      preview: "videos/thumb/test-video-2.jpg",
      status: 0,
      views: 500,
      guideFunctionElementId: 1,
      guideFunctionElement: null,
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-11-01T11:45:15.627",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 1,
      title: "Digital Transformation Vision",
      url: "videos/test-video-1.mp4",
      preview: "videos/thumb/test-video-1.jpg",
      status: 0,
      views: 500,
      guideFunctionElementId: 1,
      guideFunctionElement: null,
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-11-01T11:45:15.627",
      u_status: "success",
      u_errormsg: "",
    },
  ]);
  const [current, setCurrent] = useState(null);
  const [redirect, setRedirect] = useState(0);

  useEffect(() => {
    let mm = document.getElementById("main-menu")?.getElementsByTagName("li");
    for (let index = 0; index < mm?.length; index++) {
      const element = mm[index];
      if (element) {
        element.classList.remove("ant-menu-item-selected");
        element.classList.remove("ant-menu-item-active");
      }
    }
    if (mm?.length > 1) {
      mm[4].classList.add("ant-menu-item-selected");
    }
    window.scrollTo(0, 0);

  }, []);

  useEffect(() => {
    setRedirect(0);
  }, [redirect]);

  return (
    <div>
      {redirect === 1 ? (
        <Redirect to="/see" />
      ) : (
        <>
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item onClick={() => setRedirect(1)}>
              <CaretLeftOutlined id="bdcr-icon" />
              SEE
            </Breadcrumb.Item>
            <Breadcrumb.Item>VIDEOS</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
              {/* <h1 className="title-main"></h1> */}

              <p className="subtitle-main-v">
                <b>Sometimes a video says it all</b> - it is easier to watch a
                video rather than read the application notes or other
                documentation.
              </p>
              <p className="subtitle-main-v">
                We will be updating our collection of videos over time to help
                give you better insights on our IT applications, new
                developments in the technology arena and our team related news.
              </p>
              <p className="subtitle-main-v">
                Created by the specialists, for our users, spend a few minutes
                exploring our video library and learn more. We look forward to
                helping you with your IT needs.
              </p>
              <p className="subtitle-main-highlight">
                We look forward to helping you with your IT needs.
              </p>
            </Col>
          </Row>
          <Row className="watch-row">
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 16 }}>
              <div className="video-viewer">
                {current ? (
                  <>
                    <ReactPlayer
                      url={require("../../media/" + current?.url).default}
                      className="watch-player"
                      playing
                      controls
                    />
                    <h1 className="title-main">{current?.title}</h1>
                    <p className="watch-date">
                      {/* <span>{current?.status==0?'Uploaded':''}</span> <span className="separate">.</span>  */}
                      <span>
                        {current
                          ? moment(current.createdon).format("YYYY-MMMM-DD")
                          : ""}
                      </span>
                    </p>
                  </>
                ) : watchData ? (
                  watchData.length > 0 ? (
                    <>
                      <ReactPlayer
                        url={
                          require("../../media/" + watchData[0]?.url).default
                        }
                        className="watch-player"
                        playing
                        controls
                      />
                      <h1 className="title-main">{watchData[0]?.title}</h1>
                      <p className="watch-date">
                        {/* <span>{current?.status==0?'Uploaded':''}</span> <span className="separate">.</span>  */}
                        <span>
                          {moment(watchData[0]?.createdon).format(
                            "YYYY-MMMM-DD"
                          )}
                        </span>
                      </p>
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 7 }}>
              <div className="left-video">
                <div className="all-videos">
                  {watchData?.length > 0 ? (
                    watchData?.map((video, index) => (
                      <Row className="video-row" onClick={() => {
                        setCurrent(video)
                      }}>
                        <Col>
                          <img
                            src={
                              require("../../media/" + video?.preview).default
                            }
                            className="video-preview"
                          />
                        </Col>
                        <Col>
                          <p className="video-title">{video?.title}</p>
                          <p className="video-desc">
                            {video?.views}+ views{" "}
                            <span className="separate">.</span>{" "}
                            {moment(video?.createdon).fromNow()}
                          </p>
                          <p className="video-status">
                            {moment(video?.createdon).date() == moment().date()
                              ? "New"
                              : ""}
                          </p>
                        </Col>
                      </Row>
                    ))
                  ) : (
                    <Empty image={emptyicon} style={{ width: "97%" }} />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
