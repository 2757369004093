import { CaretLeftOutlined } from "@ant-design/icons";
import { Breadcrumb, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";

export default function WhatIsQuantum() {
  const [wdata1, setWdata1] = useState([
    {
      id: 4,
      title: "1",
      description: "Quantum - our digital transformation \r\nproject.",
      backgroundUrl: "",
      items: [],
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-11-15T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 5,
      title: "1",
      description:
        "In the next 3 years we will be digitising,\r\nautomating, innovating and transforming\r\nour entire group.",
      backgroundUrl: "",
      items: [],
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-11-15T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 6,
      title: "1",
      description:
        "This will help our businesses to innovate,\r\nand improve the way we work, improve\r\nthe way we serve our customers and help\r\nour organisation to become leaner and\r\nmore competitive and grow faster.",
      backgroundUrl: "",
      items: [],
      p_user: "",
      create_byName: "",
      createdon: "0001-01-01T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 7,
      title: "1",
      description:
        "In a nutshell, Quantum will help us to\r\ntransform and become an organisation\r\nready for the new digital era that is\r\nmoving at a rapid speed.",
      backgroundUrl: "",
      items: [],
      p_user: "",
      create_byName: "",
      createdon: "0001-01-01T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 8,
      title: "1",
      description:
        "Overtime we aim to become leaders in\r\neach area we operate in.",
      backgroundUrl: "",
      items: [],
      p_user: "",
      create_byName: "",
      createdon: "0001-01-01T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 9,
      title: "1",
      description: "Innovate  >  Transform  >  Lead",
      backgroundUrl: "",
      items: [],
      p_user: "",
      create_byName: "",
      createdon: "0001-01-01T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
  ]);
  const [wdata2, setWdata2] = useState([
    {
      id: 10,
      title: "2",
      description:
        "We have grown significantly over the \r\nlast 42 years but our systems and \r\nprocesses supporting our businesses \r\nalso need to change to support this \r\nexciting growth.",
      backgroundUrl: "",
      items: [],
      p_user: "",
      create_byName: "",
      createdon: "0001-01-01T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 11,
      title: "2",
      description:
        "The systems that will be implemented \r\nunder Quantum will help our group to \r\nbecome faster, more efficient and \r\ndrive growth.",
      backgroundUrl: "",
      items: [],
      p_user: "",
      create_byName: "",
      createdon: "0001-01-01T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 12,
      title: "2",
      description:
        "Quantum’s first mission is to replace \r\nour current ERP system.",
      backgroundUrl: "",
      items: [],
      p_user: "",
      create_byName: "",
      createdon: "0001-01-01T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 13,
      title: "2",
      description:
        "We will be moving to a state-of-the-art \r\ncloud-based ERP system called Oracle \r\nFusion.",
      backgroundUrl: "",
      items: [],
      p_user: "",
      create_byName: "",
      createdon: "0001-01-01T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 14,
      title: "2",
      description:
        "This system is the world’s first true \r\npublic cloud system used by over \r\n20,000 businesses across the globe.",
      backgroundUrl: "",
      items: [],
      p_user: "",
      create_byName: "",
      createdon: "0001-01-01T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 15,
      title: "2",
      description:
        "It comes bundled with digital tools \r\nsuch as Artificial Intelligence (AI) and \r\nMachine Learning (ML) that will help \r\nour employees to work smarter and \r\ndeliver more value to our organisation.",
      backgroundUrl: "",
      items: [],
      p_user: "",
      create_byName: "",
      createdon: "0001-01-01T00:00:00",
      u_status: "success",
      u_errormsg: "",
    },
  ]);

  const [redirect, setRedirect] = useState(0);
  useEffect(() => {
    let mm = document.getElementById("main-menu")?.getElementsByTagName("li");
    for (let index = 0; index < mm?.length; index++) {
      const element = mm[index];
      if (element) {
        element.classList.remove("ant-menu-item-selected");
        element.classList.remove("ant-menu-item-active");
      }
    }
    if (mm?.length > 1) {
      mm[1].classList.add("ant-menu-item-selected");
    }
    window.scrollTo(0, 0);

  }, []);

  useEffect(() => {
    setRedirect(0);
  }, [redirect]);

  return (
    <div className="erp-df">
      {redirect === 1 ? (
        <Redirect to="/connect" />
      ) : (
        <>
          <Row>
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 6 }}
              className="erp-df-col"
            >
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => setRedirect(1)}>
                  <CaretLeftOutlined id="bdcr-icon" />
                  CONNECT
                </Breadcrumb.Item>
                <Breadcrumb.Item>WHAT IS QUANTUM</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>

          <Row className="wq-page">
            <Col span="md-10">
              <h1 className="title-main">What is Quantum?</h1>
              {wdata1?.map((e, i) => (
                <p
                  className={
                    i == wdata1.length - 1
                      ? "subtitle-main-highlight"
                      : "subtitle-main"
                  }
                >
                  {e.description}
                </p>
              ))}
            </Col>
            <Col span="md-10">
              <h1 className="title-main">Why do we need Quantum?</h1>
              {wdata2?.map((e, i) => (
                <p className="subtitle-main">{e.description}</p>
              ))}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
