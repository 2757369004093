import { CaretLeftOutlined } from "@ant-design/icons";
import { Breadcrumb, Carousel, Col, Image, Row } from "antd";
import QPhasel from "../../media/Updated-Proj-Sched-23.png";
import QPhased from "../../media/Updated-Proj-Sched-24.png";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";

export default function QPhases() {
  const [redirect, setRedirect] = useState(0);
  useEffect(() => {
    let mm = document.getElementById("main-menu")?.getElementsByTagName("li");
    for (let index = 0; index < mm?.length; index++) {
      const element = mm[index];
      if (element) {
        element.classList.remove("ant-menu-item-selected");
        element.classList.remove("ant-menu-item-active");
      }
    }
    if (mm?.length > 1) {
      mm[1].classList.add("ant-menu-item-selected");
    }
    window.scrollTo(0, 0);

  }, []);
  useEffect(() => {
    setRedirect(0);
  }, [redirect]);

  return (
    <div className="erp-df">
      {redirect === 1 ? (
        <Redirect to="/connect" />
      ) : (
        <>
          <Row>
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 6 }}
              className="erp-df-col"
            >
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => setRedirect(1)}>
                  <CaretLeftOutlined id="bdcr-icon" />
                  CONNECT
                </Breadcrumb.Item>
                <Breadcrumb.Item>Q PHASES</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>

          <Row style={{ justifyContent: "space-between" }}>
            <Col style={{ marginTop: "1%" }}>
              <h1 className="title-main">The schedule for Quantum</h1>

              <Image
                src={
                  localStorage.getItem("theme")
                    ? localStorage.getItem("theme") == "light"
                      ? QPhased
                      : QPhasel
                    : QPhasel
                }
                preview={window.innerWidth > 780? false : true}
                style={{ width: "98%", marginLeft: "1%" }}
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
