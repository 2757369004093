import {
  CaretLeftOutlined,
  LeftOutlined,
  RightOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Button, Col, Image, Modal, Row,Space , Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import moment from "moment";
import emptyicon from "../../media/icons/camera-26.png";
import emptyicond from "../../media/icons/camera-27.png";

const pageSize = 1;
export default function Gallery() {
  const [galleryData, setGalleryData] = useState([]);
  const [redirect, setRedirect] = useState(0);
  const [viewAlbum, setViewAlbum] = useState(false);

  const [albums, setAlbums] = useState([]);
  const [albumSlider, setAlbumSlider] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [current, setCurrent] = useState(1);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);

  function handleChange(page, increase) {
    let min = 0;
    let cur = 0;
    if (increase) {
      if (page <= 0) {
        page = 0;
      }
      min = page + increase;
      cur = page + increase;
    } else {
      min = page <= 0 ? 0 : page;
      cur = page <= 0 ? 0 : page;
    }

    if (min < 0) {
      min = 0;
    }
    if (min == albums.galleryImages?.length) {
      min = albums.galleryImages?.length - 1;
    }
    if (cur < 0) {
      cur = 0;
    }
    if (cur == albums.galleryImages?.length) {
      cur = albums.galleryImages?.length - 1;
    }
    setMinIndex(min);
    setCurrent(cur);

    let mm = increase ? (increase > 0 ? cur + 1 : page) : page + 1;
    if (mm >= albums.galleryImages?.length) {
      mm = albums.galleryImages?.length;
    }
    setMaxIndex(mm == 0 ? 1 : mm);
  }
  useEffect(() => {
    // fetch("https://api.github.com/gists/public")
    // .then((response) => response.json())
    // .then((teams) =>{
    //     console.log("fist" ,teams[0])
    //         setEData(teams);
    //         setTotalPage(teams.length / pageSize);
    //         setMinIndex(0);
    //         setMaxIndex(pageSize);
    //     }
    // );

    setTotalPage(albums?.galleryImages?.length / pageSize);
    setMinIndex(0);
    setMaxIndex(
      pageSize > albums?.galleryImages?.length
        ? albums?.galleryImages?.length
        : pageSize
    );

    let mm = document.getElementById("main-menu")?.getElementsByTagName("li");
    for (let index = 0; index < mm?.length; index++) {
      const element = mm[index];
      if (element) {
        element.classList.remove("ant-menu-item-selected");
        element.classList.remove("ant-menu-item-active");
      }
    }
    if (mm?.length > 1) {
      mm[4].classList.add("ant-menu-item-selected");
    }
    window.scrollTo(0, 0);

  }, []);

  useEffect(() => {
    setRedirect(0);
  }, [redirect]);

  return (
    <div>
      {redirect === 1 ? (
        <Redirect to="/see" />
      ) : (
        <>
          <Modal
            title="title"
            footer={[]}
            centered
            width={"600px"}
            visible={albumSlider}
            onOk={() => {
              setAlbumSlider(false);
            }}
            onCancel={() => {
              setAlbumSlider(false);
            }}
          >
            <div className="gallery-slider-modal">
              {albums
                ? albums.galleryImages?.map(
                    (alb, index) =>
                      index >= minIndex &&
                      index < maxIndex && (
                        <div className="gallery-slider-content">
                          <div className="modal-arrows">
                            <LeftOutlined
                              onClick={() => handleChange(index, -1)}
                            />
                            <RightOutlined
                              onClick={() => handleChange(index, +1)}
                            />
                          </div>
                          <Button
                            className="share-btn"
                            onClick={() => {
                              javascript: window.open(
                                `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                                  alb?.path
                                )}&t=TITLE`,
                                "",
                                "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600"
                              );
                              return false;
                            }}
                          >
                            <ShareAltOutlined /> SHARE
                          </Button>

                          {/* <p style={{color:'#000'}}>{alb?.path} {index}{minIndex}{maxIndex}</p>         */}
                          <img
                            src={require("../../media/" + alb?.path).default}
                            height="600"
                            width="600"
                          ></img>
                        </div>
                      )
                  )
                : ""}
            </div>
          </Modal>
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item onClick={() => setRedirect(1)}>
              <CaretLeftOutlined id="bdcr-icon" />
              SEE
            </Breadcrumb.Item>
            {viewAlbum ? (
              <Breadcrumb.Item
                onClick={() => {
                  setViewAlbum(false);
                }}
              >
                GALLERY
              </Breadcrumb.Item>
            ) : (
              <Breadcrumb.Item>GALLERY</Breadcrumb.Item>
            )}
          </Breadcrumb>
          <div>
            {viewAlbum ? (
              <>
                <div className="album-header">
                  <p className="album-s-title">{albums?.title}</p>
                  <p className="album-s-desc">
                    {moment(albums?.createdon).fromNow()}
                  </p>
                </div>
                <Row className="wrapper">
                  {albums
                    ? albums.galleryImages?.map((alb, index) => (
                        <Col
                          xs={{ span: 24 }}
                          md={{ span: 10 }}
                          lg={{ span: 7 }}
                        >
                          <div
                            onClick={() => {
                              handleChange(index);
                              setAlbumSlider(true);
                            }}
                            style={{
                              background: `transparent url(${
                                require("../../media/" + alb?.path).default
                              }) 0% 0% no-repeat padding-box`,
                              borderRadius: "6px",
                              backgroundSize: "cover",
                              height: "318px",
                            }}
                          ></div>
                        </Col>
                      ))
                    : ""}
                </Row>
              </>
            ) : (
              <Row className="wrapper">
                {galleryData.length > 0 ? (
                  galleryData.map((album, index) => (
                    <Col xs={{ span: 24 }} md={{ span: 10 }} lg={{ span: 7 }}>
                      <div
                        onClick={() => {
                          // var f = galleryData?.filter(function (gallery) { // filter first for friends
                          //     // return gallery.owner.id === album.owner.id // returns a new array
                          //     return true
                          //   });

                          setAlbums(galleryData[index]);
                          setViewAlbum(true);
                        }}
                        style={{
                          background: `transparent url(${
                            require("../../media/" + album?.preview).default
                          }) 0% 0% no-repeat padding-box`,
                          borderRadius: "6px",
                          backgroundSize: "cover",
                          height: "318px",
                        }}
                      >
                        <img
                          src={
                            require("../../media/icons/more-pic-icon.png")
                              .default
                          }
                          width="40"
                          height="40"
                          className="gallery-icon"
                        />
                      </div>
                      <p className="album-title">{album?.title}</p>
                      <p className="album-desc">
                        {album?.galleryImages.length} image
                        {album?.galleryImages.length > 1 ? "s" : ""}{" "}
                        <span className="separate">.</span>
                        {moment(album?.createdon).fromNow()}
                      </p>
                    </Col>
                  ))
                ) : (
                  <Row style={{display:'grid', width:'100%'}}>
                  {/* <Space>
          <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
          <Skeleton.Avatar active={true} size={"default"} shape={"circle"} />
          <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
          <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
          <Skeleton.Avatar active={true} size={"default"} shape={"circle"} />
          <Skeleton.Input  active={true} size={"default"} shape={"default"} block={false} />
        </Space> */}
                  <Image
                    preview={false}
                    className="emptyoops"
                    src={localStorage.getItem("theme")
                    ? localStorage.getItem("theme") == "dark"
                      ?emptyicon:emptyicond:emptyicon}
                  /></Row>
                )}
              </Row>
            )}
          </div>
        </>
      )}
    </div>
  );
}
