// Toggle.js
import React from 'react'
import { func, string } from 'prop-types';
import styled from 'styled-components';

import { Switch } from 'antd';
const Toggle = ({ theme, toggleTheme }) => {
  const isLight = theme === 'light';
  return (
    
    <>
        <Switch onChange={toggleTheme} className="footer-swich" /><span className="footer-mode">{theme === 'light' ?' Light Mode':' Dark Mode'}</span>
    </>  
  );
};

Toggle.propTypes = {
  theme: string.isRequired,
  toggleTheme: func.isRequired,
}

export default Toggle;