import { empConstants } from '../_constants';
const empData = {
    // data:{
    // ad_user: "",
    // department: "",
    // designation: "",
    // emp_ID: null,
    // emp_name: "",
    // status: "",
    // u_errormsg: "",
    // u_status: "success"}
    data:{
        slno: null,
        tokenid: null,
        userid: null,
        userempid: null,
        username: null,
        redirecturl: null,
        isexpired: null,
        create_time: null,
        u_status: null,
        u_errmsg: null
    }
}

export function emp(eee = empData, action) {
    switch (action.type) {
    
    case empConstants.UPDATE_SUCCESS:
        
        return {
            data: Object.assign(eee.data, action.data )
        };
    default:
        return eee
    }
}