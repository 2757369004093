import { CaretLeftOutlined, DownloadOutlined } from "@ant-design/icons";
import { Breadcrumb, Col, Row, Tooltip } from "antd";
import axios from "axios";
import moment from "moment";
import QRCode from "qrcode.react";

import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import React, { useEffect, useRef, useState } from "react";
import { Redirect } from "react-router";
import triangleIcon from "../../media/icons/Q-Triangle.png";

export default function Qpass() {
  const [points, setPoints] = useState(null);
  const [redirect, setRedirect] = useState(0);
  const [qpassdatalist, setQpassdatalist] = useState([]);
  const qrRef = useRef();
  const [url, setUrl] = useState("00000000"); // empid

  const [loginuser, setLoginuser] = useState({
    userempid: localStorage.getItem("ency_empid")
      ? CryptoAES.decrypt(
          localStorage.getItem("ency_empid").toString(),
          "ksdj566d-qvqvsrerwe-asjaand64jh-2re3saX"
        ).toString(CryptoENC)
      : "",
    username: localStorage.getItem("ency_empname"),
  });

  const downloadQRCode = (evt) => {
    evt.preventDefault();
    // @ts-ignore
    let canvas = qrRef.current.querySelector("canvas");
    let image = canvas.toDataURL("image/png");
    let anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = `qr-code.png`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    setUrl("");
  };
  const qrCode = (
    <QRCode
      id="qrCodeElToRender"
      size={250}
      value={url}
      bgColor="white"
      fgColor="#141926"
      level="Q"
      imageSettings={{
        src: triangleIcon, // triangle only
        excavate: true,
        width: 250 * 0.2,
        height: 250 * 0.2,
      }}
    />
  );
  useEffect(() => {
    let mm = document.getElementById("main-menu")?.getElementsByTagName("li");
    for (let index = 0; index < mm?.length; index++) {
      const element = mm[index];
      if (element) {
        element.classList.remove("ant-menu-item-selected");
        element.classList.remove("ant-menu-item-active");
      }
    }
    if (mm?.length > 1) {
      mm[5].classList.add("ant-menu-item-selected");
    }
    window.scrollTo(0, 0);

  }, []);

  useEffect(() => {
    
      setRedirect(0);
    
  }, [redirect]);

  return (
    <div>
      {redirect === 1 ? (
        <Redirect to="/learn" />
      ) : (
        <>
          <div className="qpass-header">
            <Breadcrumb>
              <Breadcrumb.Item onClick={() => setRedirect(1)}>
                <CaretLeftOutlined id="bdcr-icon" />
                LEARN
              </Breadcrumb.Item>
              <Breadcrumb.Item>QPASS</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <Row className="qpass-body">
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 14 }}>
              <h1 className="title-main">QPass</h1>

              {qpassdatalist.length > 0 ? (
                qpassdatalist.map((e, i) => (
                  <p
                    className={
                      e.sts ? "subtitle-main-highlight" : "subtitle-main"
                    }
                  >
                    {e.title}
                  </p>
                ))
              ) : (
                <>
                  <p className="subtitle-main">
                    <b>Your learning journey, record it along the way.</b>
                  </p>
                  <p className="subtitle-main">
                  Qpass is for every member of the Ali & Sons Group. It is our way of engaging and encouraging you to learn and thanking you for your time and effort. 
                  </p>
                  <p className="subtitle-main">The more you learn, the more reward points you earn. More points mean exciting rewards are waiting for you. 
                  </p>
                  <p className="subtitle-main-highlight">
                  Ensure to use your Qpass to enroll for any Quantum training and to redeem your rewards.
                  </p>
                </>
              )}
            </Col>
            <Col
              className="qp-col"
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 5 }}
            >
              <div className="qr-container__qr-code" ref={qrRef}>
                {qrCode}
              </div>

              <p>
                {loginuser
                  ? loginuser.username
                    ? loginuser.username
                    : "Employee Name"
                  : "Employee Name"}
              </p>
              <div>
                <p className="rewords-title">POINTS</p>
                <p className="rewords-points">
                  {points
                    ? points.points
                    : localStorage.getItem("qpoints")
                    ? localStorage.getItem("qpoints").toString()
                    : "0"}
                </p>
              </div>
              <form onSubmit={downloadQRCode} className="qr-container__form">
                <input
                  type="text"
                  hidden={true}
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  placeholder="https://example.com"
                />
                <Tooltip title="Download QR Code">
                  <button type="submit">
                    <DownloadOutlined />
                  </button>
                </Tooltip>
                {/* <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent("https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/How_to_use_icon.svg/1200px-How_to_use_icon.svg.png")}&t=TITLE`}
                        onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
                        target="_blank" title="Share on Facebook"><ShareAltOutlined />
                      </a> */}
                {/* <Tooltip title="Share QR Code">

                      <Button 
                      onClick={()=>{
                        javascript:window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent("https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/How_to_use_icon.svg/1200px-How_to_use_icon.svg.png")}&t=TITLE`, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;
                        }}>
                        <ShareAltOutlined />
                      </Button>
                      </Tooltip> */}
              </form>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
