import Search from 'antd/lib/input/Search'
import { Header } from 'antd/lib/layout/layout'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { QrcodeOutlined, SearchOutlined, SettingOutlined ,MenuOutlined, MailOutlined, RightOutlined} from '@ant-design/icons';
import { Input ,Drawer,Menu } from 'antd';


import homei from '../media/mobile-icons/home.svg';
import connecti from '../media/mobile-icons/connect.svg';
import contacti from '../media/mobile-icons/contact.svg';
import learni from '../media/mobile-icons/learn.svg';
import seei from '../media/mobile-icons/see.svg';

import homeid from '../media/icons/dark/home.svg';
import connectid from '../media/icons/dark/connect.svg';
import contactid from '../media/icons/dark/contact.svg';
import learnid from '../media/icons/dark/learn.svg';
import seeid from '../media/icons/dark/see.svg';

import logodark from '../media/logo_light.png';
import logolight from '../media/logo_dark.png';
import SubMenu from 'antd/lib/menu/SubMenu';
import Toggle from '../Toggle';
export default class HeaderSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showSearch:false,
            searchText:"",
            openDrawer:false,
            loginuser:null
        }
    }
    
    render() {
        const {loginuser,showSearch ,searchText ,openDrawer} =this.state;
        const {setLocation ,theme ,toggleTheme} = this.props
        return (
                <Header>
      
      <div className="web">
      <div className="logo">
        <Link to='/' style={{width:'183px', height:'32px', display:'flex'}}><img alt="Quantum logo" src={theme== 'light'?logolight : logodark} width="100%" /></Link>
      </div>
      
      <div style={{display:showSearch?"contents":"none"}}>
        <Search
          placeholder="input search text" 
          enterButton="Search"
          size="middle" 
          loading={this.state.searchText !=""}  
          id="header-search" 
          value={this.state.searchText}
          onChange={(e)=>{this.setState({searchText:e.target.value})}}
       />
      </div>
      <Menu mode="horizontal" defaultSelectedKeys={['1']} id='main-menu' >
        
        {loginuser?.role=="admin"?
            <Menu.Item key={1} onClick={()=>{setLocation("/admin")}}><Link to='/admin'>DASHBOARD</Link></Menu.Item>
            :
        <Menu.Item key={1} onClick={()=>{setLocation("/")}}><Link to='/'>HOME</Link></Menu.Item>}
        <Menu.Item key={2} onClick={()=>{setLocation("/connect")}}><Link to='/connect' >CONNECT</Link></Menu.Item>
        <Menu.Item key={3} onClick={()=>{setLocation("/learn")}}><Link to='/learn' >LEARN</Link></Menu.Item>
        <Menu.Item key={4} onClick={()=>{setLocation("/see")}}><Link to='/see' >SEE</Link></Menu.Item>
        <Menu.Item key={5} onClick={()=>{setLocation("/contact-us")}}><Link to='/contact-us' >CONTACT US</Link></Menu.Item>
        <Menu.Item key={7} className="qritem" onClick={()=>{setLocation("/q-pass")}}><Link to='/q-pass' ><QrcodeOutlined /></Link></Menu.Item>
        {/* {new Array(15).fill(null).map((_, index) => {
          const key = index + 1;
          return <Menu.Item key={key}>{`nav ${key}`}</Menu.Item>;
        })} */}
      </Menu> 
      </div>
      <div className="mobile">
      <Drawer
        title={``}
        placement="left"
        width={'100%'}
        onClose={()=>this.setState({openDrawer:false})}
        visible={openDrawer}
      >
        <div className="drawer-content">
        <Menu mode="inline" defaultSelectedKeys={['1']} id='main-menu-mobile' >
        <Menu.Item key={6} className="search-icon" style={{width:'100%'}}>
        {/* <Search 
          placeholder="input search text" 
          // enterButton="Search"
          size="small" 
          loading={searchText !=""}  
          id="header-search" 
          value={searchText}
          onChange={(e)=>{setSearchText(e.target.value)}}
       /> */}
       <Input size="small" 
        
       id="header-search-mobile" 
       value={searchText}
       onChange={(e)=>{this.setState({searchText: e.target.value})}}
       placeholder="Search" 
       prefix={<SearchOutlined />}
       style={{display:'none'}} 
       />
  
  
       </Menu.Item>
        {loginuser?.role=="admin"?
            <Menu.Item key="1">
            <Link to='/admin' onClick={()=>{
              this.setState({openDrawer: false});
              // setLocation('/home')
              }}>
              <img src={theme=="dark"?homei:homeid} width="16" style={{marginRight:'1rem'}} />DASHBOARD
              </Link>
          </Menu.Item>

           
          :
          <Menu.Item key="1">

            <Link to='/' onClick={()=>{
              this.setState({openDrawer: false});
              // setLocation('/home')
              }}>
              <img src={theme=="dark"?homei:homeid} width="16" style={{marginRight:'1rem'}} />HOME
              </Link>
          </Menu.Item>}

          <SubMenu key={"sub1"} icon={<img src={theme=="dark"?connecti:connectid} width="16"/>} title="CONNECT">
          <Menu.Item key="4"><Link to='/what-is-quantum' onClick={()=>{
            this.setState({openDrawer: false});
          }}>What Is Quantum</Link></Menu.Item>
              <Menu.Item key="3"><Link to='/q-impact' onClick={()=>{
                // setLocation('/q-impact');
                this.setState({openDrawer: false});
              }}>Quantum Impact</Link></Menu.Item>
              <Menu.Item key="13"><Link to='/q-phases' onClick={()=>{
                // setLocation('/q-impact');
                this.setState({openDrawer: false});
              }}>Quantum Phases</Link></Menu.Item>
            <Menu.Item key="2"><Link to='/meet-the-team' onClick={()=>{
              // setLocation('/meet-the-team');
              this.setState({openDrawer: false});
            }}>Quantum Team</Link></Menu.Item>
        </SubMenu>

        <SubMenu key={"sub2"} icon={<img src={theme=="dark"?learni:learnid} width="16"/>} title="LEARN">
            <Menu.Item key="5"><Link to='/employee-guide' onClick={()=>{
              // setLocation('/employee-guide');
              this.setState({openDrawer: false});
            }}>Employee Guide</Link></Menu.Item>
              <Menu.Item key="7"><Link to='/q-pass' onClick={()=>{
                // setLocation('/q-pass');
                this.setState({openDrawer: false});
              }}>QPASS</Link></Menu.Item>
            <Menu.Item key="6"><Link to='/claim-rewards' onClick={()=>{
              // setLocation('/claim-rewards');
              this.setState({openDrawer: false});
            }}>Claim Rewards</Link></Menu.Item>
            <Menu.Item key="8"><Link to='/mylearning' onClick={()=>{
              // setLocation('/mylearning');
              this.setState({openDrawer: false});
            }}>My Learning</Link></Menu.Item>
        </SubMenu>
        <SubMenu key={"sub3"} icon={<img src={theme=="dark"?seei:seeid} width="16"/>} title="SEE">
            <Menu.Item key="9"><Link to='/watch-videos' onClick={()=>{
              // setLocation('/watch-videos');
              this.setState({openDrawer: false});
            }}>Watch Our Videos</Link></Menu.Item>
            <Menu.Item key="10"><Link to='/gallery' onClick={()=>{
              // setLocation('/gallery');
              this.setState({openDrawer: false});
            }}>Q Gallery</Link></Menu.Item>
        </SubMenu>
        {/* <SubMenu key="11" icon={<img src={theme=="dark"?contacti} width="16"/>} title={<Link to='/contact-us'
          onClick={()=>
            {
              this.setState({openDrawer: fals}e)
              // setLocation('/home')
              }}>CONTACT US</Link>}>
            
        </SubMenu> */}
          <Menu.Item key="11">
            <Link to='/contact-us' onClick={()=>{
              this.setState({openDrawer: false});
              // setLocation('/home')
              }}>
              <img src={theme=="dark"?contacti:connectid} width="16" style={{marginRight:'2%'}} />CONTACT US
              </Link>
          </Menu.Item>
          <Menu.Item key="12">

          <Toggle theme={theme} toggleTheme={toggleTheme} />
</Menu.Item>
{/*         
        <Menu.Item key={2} onClick={()=>{setLocation("/connect")}}><Link to='/connect' >CONNECT</Link><RightOutlined/></Menu.Item>
        <Menu.Item key={3} onClick={()=>{setLocation("/learn")}}><Link to='/learn' >LEARN</Link><RightOutlined/></Menu.Item>
        <Menu.Item key={4} onClick={()=>{setLocation("/see")}}><Link to='/see' >SEE</Link><RightOutlined/></Menu.Item>
        <Menu.Item key={5} onClick={()=>{setLocation("/contact-us")}}><Link to='/contact-us' >CONTACT US</Link><RightOutlined/></Menu.Item>
         */}
      </Menu> 
        </div>
      </Drawer>
        <div className="nav-mobile">
          <MenuOutlined className="mobile-icon" onClick={()=>this.setState({openDrawer: true}) }/>
          <div className="logo">
            <Link to="/" className="mobile-logo" style={{ margin:'auto' , display:'flex'}} ><img alt="Quantum logo" src={theme== 'light'?logolight : logodark} width="100%" /></Link>
          </div>
          <Link to='/q-pass' style={{ display:'flex'}}><QrcodeOutlined className="mobile-icon"/></Link>
        </div>
        </div>
        
            </Header>
        )
    }
}
