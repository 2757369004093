import axios from "axios";
import React, { Component } from "react";

import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import { Redirect } from "react-router";
import { Result } from "antd";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wer: "",
      redirect: 0,
      loginuser: localStorage.getItem("ency_empid")
        ? CryptoAES.decrypt(
            localStorage.getItem("ency_empid").toString(),
            "ksdj566d-qvqvsrerwe-asjaand64jh-2re3saX"
          )
        : null,
    };
  }
  async getuser() {
    let token = this.props.match.params.token;
    if (token) {
      if (
        token == "connect" ||
        token == "home" ||
        token == "learn" ||
        token == "see" ||
        token == "contact-us" ||
        token == "q-pass" ||
        token == "employee-guide" ||
        token == "claim-rewards" ||
        token == "mylearning" ||
        token == "what-is-quantum" ||
        token == "q-impact" ||
        token == "meet-the-team" ||
        token == "watch-videos" ||
        token == "gallery"
      ) {
      } else {
        await axios
          .post(
            process.env.REACT_APP_API_ENTRA + "RedirectToken/tokens",
            {
              tokenid: token, //"61407cbd-2fcc-4d18-8db9-6060cff0cc5d"
              //"tokenid"  : "9e0b621f-17fa-47d0-9342-780341eb69bd" sathish
              //  e160dee8-53c5-44e7-8cf2-ae43e86a90dc sandra
            },
            {
              params: { action: "read" },
            }
          )
          .then((res) => {
            if (res.data[0].u_status == "success") {
              //
              var ciphertext = CryptoAES.encrypt(
                res.data[0].userempid,
                "ksdj566d-qvqvsrerwe-asjaand64jh-2re3saX"
              );
              var cipheremailId = CryptoAES.encrypt(
                res.data[0].userid,
                "ksdj566d-qvqvsrerwe-asjaand64jh-2re3saX"
              );

              //store
              localStorage.setItem("ency_empid", ciphertext);
              localStorage.setItem("ency_emailid", cipheremailId);
              localStorage.setItem("ency_empname", res.data[0].username);

              // this.props.dispatch(empActions.updateInput(res.data[0]));

              let dd = decodeURIComponent(res.data[0].redirecturl);
              this.setState(
                { wer: dd == "/mycharterlist" ? "/" : null },
                () => {
                  this.setState({ redirect: 1 });
                }
              );

              // axios.get(process.env.REACT_APP_API+'api/charter/getuser',{
              //     params:{empid:res.data[0].userempid}
              // }).then((res1)=>{
              //     console.log( "employeee",res1);
              //     //    res1.data[0].emp_ID = window.btoa(res1.data[0].emp_ID);
              //     this.props.dispatch(empActions.updateInput(res1.data[0]));

              //     var ciphertext1 = CryptoAES.encrypt(res1.data[0].emp_ID, 'ksdj566d-jf2jh2sd66jh-asdja64jhas-23dsaX');

              //     //store
              //     localStorage.setItem('ency_empid', ciphertext1);
              //     //use
              //     // var _ciphertext = CryptoAES.decrypt(ciphertext.toString(), 'ksdj566d-jf2jh2sd66jh-asdja64jhas-23dsaX');
              //     // console.log("emp after stor ",_ciphertext.toString(CryptoENC));

              //     // var ciphertext = CryptoAES.encrypt('Sathish Kumar', 'ksdj566d-jf2jh2sd66jh-asdja64jhas-23dsaX');

              //     // localStorage.setItem('ency_empname', ciphertext);
              // })
              // .catch((e)=>console.log("error",e))
            } else {
              if (this.state.loginuser) {
                this.setState({ wer: "/home" }, () => {
                  this.setState({ redirect: 1 });
                });
              } else {
                this.setState({ wer: null }, () => {
                  this.setState({ redirect: 1 });
                });
              }
            }
          });
      }
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    this.getuser();
  }
  render() {
    const { redirect, wer, loginuser } = this.state;
    return (
      <div>
        {redirect === 1 ? (
          wer ? (
            <Redirect to={wer} />
          ) : (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              // extra={<Button type="primary" href={`/home/${match.params.token}`}>Back Home</Button>}
            />
          )
        ) : (
          <></>
        )}
      </div>
    );
  }
}
