import React, { Component } from "react";
import { Col, Row, Input, Button, Form, Modal } from "antd";
import callicon from "../media/icons/call.svg";
import emailicon from "../media/icons/email.svg";
import {CheckCircleOutlined} from "@ant-design/icons";
import callicond from "../media/icons/dark/call.svg";
import emailicond from "../media/icons/dark/email.svg";
import axios from "axios";

const { TextArea } = Input;
export default class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: '',
      lname: '',
      email: '',
      message: '',
      mailSent:'',
      error:'',
      opensucess:false
    }
  }
  componentDidMount(){
    window.scrollTo(0, 0);

  }
  render() {
    return (
      <>
      <Modal
            title="title"
            footer={[]}
            centered
            width={"600px"}
            visible={this.state.opensucess}
            onOk={() => {
              this.setState({opensucess:false})
            }}
            onCancel={() => {
              this.setState({opensucess:false})
            }}
          >
            <div className="thank-box">
              
              
              <p className="thanku" > {" "}Thank you for your feedback!</p>
              
              <Button
                className="thanku-home"
                onClick={() => {
                  this.setState({opensucess:false})
                  window.location.reload();
                }}
              ><CheckCircleOutlined style={{ fontSize:'20px' }}/>
                OK
              </Button>
            </div>
          </Modal>
      <Row
        className="contact-us-row"
        // style={{justifyContent: 'center'}}
      >
        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
          <h1 className="title-main">Contact Us</h1>
          <p className="desc-main-contact-us">
            Feel free to contact us and we will get back to you as soon as we
            can.
          </p>
          <div className="desc-main-contact-us">
            <p className="desc-list">
              <img
                src={
                  localStorage.getItem("theme")
                    ? localStorage.getItem("theme") == "dark"
                      ? emailicon
                      : emailicond
                    : emailicon
                }
                width={19}
              />
              <span>Email to: </span>{" "}
              <span style={{ textDecoration: "underline" }}>
                <a href = "mailto: quantum@ali-sons.com">quantum@ali-sons.com</a>
              </span>
            </p>
            <p className="desc-list">
              <img
                src={
                  localStorage.getItem("theme")
                    ? localStorage.getItem("theme") == "dark"
                      ? callicon
                      : callicond
                    : callicon
                }
                width={19}
              />
              <span>Call us: </span> <span>
              <a href="tel:+9712 6723 900">+9712 6723 900</a></span>
            </p>
          </div>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9 }}>
          <div className="left-contact-us">
            <h1>Your Feedback :</h1>
            <Form
              
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18  }}
              layout="horizontal"
              // initialValues={{ size: componentSize }}
              onValuesChange={(e,i) => console.log(i.fname,i.email , i.message)}
              size="default"
              onFinish={(v)=>{
                console.log("submit",v)
                var bodyFormData = new FormData();
                bodyFormData.append('fname', v.fname);
                bodyFormData.append('email', v.email);
                bodyFormData.append('message', v.message);
                axios({
                  method: 'post',
                  url: `/sendemail.php`,
                  headers: { 'content-type': 'application/json' },
                  data: bodyFormData,
                })
                  .then(result => {
                    this.setState({
                      opensucess:true,
                      mailSent: result.data.sent
                    });
                  })
                  .catch(error => this.setState({ opensucess:true,error: error.message }));
              }}
            >
              <Form.Item label="Your Name" name="fname"
              rules={[{ required: true, message: 'Please input your name!' }]}>
                <Input
                  //showCount maxLength={100}
                  placeholder=""
                  allowClear
                />
              </Form.Item>
              <Form.Item label="Your Email" name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}>
                <Input
                  //showCount maxLength={100}
                  placeholder=""
                  allowClear
                />
              </Form.Item>
              <Form.Item label="Message" name="message"
              rules={[{ required: true, message: 'Please input your message!' }]}>
                <TextArea
                  //showCount maxLength={100}
                  placeholder=""
                  allowClear
                />
              </Form.Item>
              <Button className="contact-us-submit" htmlType="submit">Send</Button>
            </Form>
          </div>
        </Col>
      </Row>
      </>
    );
  }
}
