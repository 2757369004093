import {
  CaretLeftOutlined,
  DeleteOutlined,
  MinusOutlined,
  PlusOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Breadcrumb,
  Button,
  Table,
  Col,
  Divider,
  Drawer,
  Modal,
  Row,
  Image,
} from "antd";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import moment from "moment";
import emptyicon from "../../media/icons/no-date-icon.png";

const pageSize = 30;

export default function ClaimRewards() {
  const [loginuser, setLoginuser] = useState({
    userempid: localStorage.getItem("ency_empid")
      ? CryptoAES.decrypt(
          localStorage.getItem("ency_empid").toString(),
          "ksdj566d-qvqvsrerwe-asjaand64jh-2re3saX"
        ).toString(CryptoENC)
      : "",
    username: localStorage.getItem("ency_empname"),
  });

  const [myhistory, setMyhistory] = useState([]);
  const [openhistory, setOpenhistory] = useState(false);
  const [rewardsdata, setRewardsData] = useState([]);
  const [carttprice, setCarttprice] = useState(0);
  const [cartbalance, setCartbalance] = useState(0);
  const [conf, setConf] = useState(false);
  const [pagedata, setPagedata] = useState([
    {
      id: 1,
      title: "Claim Rewards",
      description:
        "You have done the hard work, learned a new skill and earned valuable points. We want to reward you; go ahead, choose and claim your reward. \r\n",
      points: 0,
      userId: "00000253",
      user: null,
      products: [
        {
          id: 1,
          name: "Q Black Mug",
          preview: "claim-rewards/Q-Giveaways-07.png",
          points: 50,
          count: 126,
          requestedQty: 0,
          status: 0,
          storeId: 0,
          store: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
        {
          id: 2,
          name: "Q White Mug",
          preview: "claim-rewards/Q-Giveaways-08.png",
          points: 50,
          count: 60,
          requestedQty: 0,
          status: 0,
          storeId: 0,
          store: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
        {
          id: 4,
          name: "Q Green Mug",
          preview: "claim-rewards/Q-Giveaways-09.png",
          points: 50,
          count: 10,
          requestedQty: 0,
          status: 0,
          storeId: 0,
          store: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
        {
          id: 5,
          name: "Q Mask",
          preview: "claim-rewards/Q-Giveaways-01.png",
          points: 150,
          count: 120,
          requestedQty: 0,
          status: 0,
          storeId: 0,
          store: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
        {
          id: 6,
          name: "Q Note Book",
          preview: "claim-rewards/Q-Giveaways-14.png",
          points: 75,
          count: 100,
          requestedQty: 0,
          status: 0,
          storeId: 0,
          store: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
        {
          id: 8,
          name: "Q Black Pen",
          preview: "claim-rewards/Q-Giveaways-11.png",
          points: 40,
          count: 10,
          requestedQty: 0,
          status: 0,
          storeId: 0,
          store: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
        {
          id: 9,
          name: "Q Green Pen",
          preview: "claim-rewards/Q-Giveaways-13.png",
          points: 40,
          count: 43,
          requestedQty: 0,
          status: 0,
          storeId: 0,
          store: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
        {
          id: 13,
          name: "Q White Pen",
          preview: "claim-rewards/Q-Giveaways-12.png",
          points: 40,
          count: 10,
          requestedQty: 0,
          status: 0,
          storeId: 0,
          store: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
        {
          id: 14,
          name: "Q String Bag",
          preview: "claim-rewards/Q-Giveaways-10.png",
          points: 75,
          count: 10,
          requestedQty: 0,
          status: 0,
          storeId: 0,
          store: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
        {
          id: 15,
          name: "Q Sun Shade",
          preview: "claim-rewards/Q-Giveaways-16.png",
          points: 150,
          count: 10,
          requestedQty: 0,
          status: 0,
          storeId: 0,
          store: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
        {
          id: 16,
          name: "Q Black TShirt",
          preview: "claim-rewards/Q-Giveaways-02.png",
          points: 200,
          count: 10,
          requestedQty: 0,
          status: 0,
          storeId: 0,
          store: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
        {
          id: 18,
          name: "Q White TShirt",
          preview: "claim-rewards/Q-Giveaways-03.png",
          points: 200,
          count: 10,
          requestedQty: 0,
          status: 0,
          storeId: 0,
          store: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
        {
          id: 19,
          name: "Q Green TShirt",
          preview: "claim-rewards/Q-Giveaways-04.png",
          points: 200,
          count: 10,
          requestedQty: 0,
          status: 0,
          storeId: 0,
          store: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
        {
          id: 25,
          name: "Q Black Metal Bottle",
          preview: "claim-rewards/Q-Giveaways-05.png",
          points: 250,
          count: 10,
          requestedQty: 0,
          status: 0,
          storeId: 0,
          store: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
        {
          id: 26,
          name: "Q White Metal Bottle",
          preview: "claim-rewards/Q-Giveaways-06.png",
          points: 250,
          count: 10,
          requestedQty: 0,
          status: 0,
          storeId: 0,
          store: null,
          p_user: null,
          create_byName: null,
          createdon: null,
          u_status: "success",
          u_errormsg: "",
        },
      ],
      p_user: "00000000",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-24T09:06:04.583",
      u_status: "success",
      u_errormsg: "",
    },
  ]);
  const [cartProducts, setCartProducts] = useState([]);
  const [mycart, setMycart] = useState(null);
  const [toRemove, setToRemove] = useState(-1);

  const [totalPage, setTotalPage] = useState(0);
  const [current, setCurrent] = useState(1);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);

  const [countt, setCountt] = useState(0);
  const [placement, setPlacement] = useState("right");

  const [cartVisible, setCartVisible] = useState(false);
  const [payment, setPayment] = useState(false);
  const [redirect, setRedirect] = useState(0);

  const columns = [
    // { title: 'ID', dataIndex: 'id', key: 'id'
    // ,  sorter: (a, b) => a.id - b.id},
    {
      title: "Points",
      dataIndex: "total",
      key: "total",
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: "Status",
      dataIndex: "invoiceStatus",
      key: "invoiceStatus",
      render: (sts) => (
        <span>{sts == 1 ? "Waiting for collection" : "Collected"}</span>
      ),
      sorter: (a, b) => a.invoiceStatus - b.invoiceStatus,
    },
    {
      title: "Ordered On",
      dataIndex: "createdon",
      key: "createdon",
      sorter: (a, b) => moment(a.createdon) - moment(b.createdon),
      render: (stdate) => <span>{moment(stdate).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "Collected On",
      dataIndex: "modifyon",
      key: "modifyon",
      sorter: (a, b) => moment(a.modifyon) - moment(b.modifyon),
      render: (stdate) => <span>{moment(stdate).format("DD-MM-YYYY")}</span>,
    },
  ];

  useEffect(() => {
    if (toRemove != -1) showConfirm();
  }, [toRemove]);

  function updatemycart() {}

  useEffect(() => {
    updatemycart();
    updatepagedata();
  }, [loginuser]);

  useEffect(() => {
    if (mycart) {
      mycart.products.forEach((element) => {
        let newArr = [...rewardsdata]; // copying the old datas array
        // newArr[index].status = 2; // replace e.target.value with whatever you want to change it to
        newArr.forEach((nn) => {
          if (nn.id == element.product.id) nn.status = 2;
        });
        setRewardsData(newArr); // ??
      });
    }
  }, [mycart]);

  
  useEffect(() => {
    setRedirect(0);
  }, [redirect]);

  function updatepagedata() {}

  useEffect(() => {
    // fetch("https://api.github.com/gists/public")
    // .then((response) => response.json())
    // .then((rewards) =>{
    //     console.log("first" ,rewards)
    //         setData(rewards);
    //         setTotalPage(rewards.length / pageSize);
    //         setMinIndex(0);
    //         setMaxIndex(pageSize);
    //     }
    // );
    updatepagedata();
    setTotalPage(rewardsdata.length / pageSize);
    setMinIndex(0);
    setMaxIndex(pageSize);

    let mm = document.getElementById("main-menu")?.getElementsByTagName("li");
    for (let index = 0; index < mm?.length; index++) {
      const element = mm[index];
      if (element) {
        element.classList.remove("ant-menu-item-selected");
        element.classList.remove("ant-menu-item-active");
      }
    }
    if (mm?.length > 1) {
      mm[2].classList.add("ant-menu-item-selected");
    }
    window.scrollTo(0, 0);
    
  }, []);

  useEffect(() => {
    if (pagedata) {
      setRewardsData(pagedata[0].products);
      setCartbalance(pagedata[0].points);
    }

    cartProducts?.forEach((cp) => {
      var exist = pagedata
        ? pagedata[0].products?.find((x) => x.id == cp.item.id)
        : null;

      exist.status = 2;
    });
  }, [pagedata]);
  useEffect(() => {
    info(0);
  }, [carttprice]);
  function removeProduct(e) {
    var array = [...cartProducts]; // make a separate copy of the array
    // var index = array.indexOf(cartProducts[e])
    let ppp = pagedata ? pagedata[0].products : null;

    let newArr = ppp ? ppp.filter((p) => p.id == array[e].item.id) : [];
    newArr[0].status = 0;
    if (e !== -1) {
      array.splice(e, 1);
      setCartProducts(array);
      setCountt(countt - 1);
      destroyAll();
    }
  }

  function updateFieldChanged(index) {
    //add_invoice_product

    let newArr = [...rewardsdata]; // copying the old datas array
    newArr[index].status = 2; // replace e.target.value with whatever you want to change it to
    setRewardsData(newArr); // ??
    var product = {
      item: newArr[index],
      count: 1,
      id: 0,
    };

    let newcart = [...cartProducts];
    newcart.push(product);

    setCartProducts(newcart);
  }
  useEffect(() => {
    if (cartProducts?.length > 0) {
      setToRemove(-1);
      if (pagedata) {
        let p = getcarttotal(cartProducts);

        let pb = pagedata[0].points;

        setCarttprice(p);
        setCartbalance(pb - p);
      }

      //update
      if (mycart && loginuser) {
        cartProducts?.forEach((element) => {
          let pp = {
            id: element.item.id,
            count: element.count,
            productId: element.item.id,
            invoiceId: mycart.id,
            p_user: loginuser?.userempid,
          };
          if (mycart.products.length > 0) {
            var e = mycart.products.some((item) => pp.id === item.id);

            if (e) {
              var ed = mycart.products.filter((item) => pp.id === item.id);
              ed[0].count = pp.count;
            } else {
              mycart.products.pop(pp);
            }
          } else {
            var ed = mycart.products.filter((item) => pp.id === item.id);
            // pp.productId = ed.product.id;
            mycart.products.push(pp);
          }
        });
      }
    }
  }, [cartProducts]);

  function handleChange(page) {
    setCurrent(page);
    setMinIndex((page - 1) * pageSize);
    setMaxIndex(page * pageSize);
  }

  function viewCart() {
    setCartVisible(true);
  }
  function onClose() {
    setCartVisible(false);
  }

  function info(sts) {
    //update
    if (mycart && loginuser) {
      cartProducts?.forEach((element) => {
        let pp = {
          id: 0,
          count: element.count,
          productId: element.item.id,
          invoiceId: mycart.id,
          p_user: loginuser?.userempid,
        };

        var e = mycart.products.some((item) => pp.productId === item.productId);
        if (e) {
          var ed = mycart.products.filter(
            (item) => pp.productId === item.productId
          );
          ed[0].count = pp.count;
        } else {
          mycart.products.push(pp);
        }
      });
    }
    // Modal.info({
    //     width:"60%",
    //     footer:{[
    //         <Button key="back" onClick={}>
    //           Return
    //         </Button>,
    //         <Button key="submit" type="primary" onClick={this.handleOk}>
    //           Submit
    //         </Button>
    //       ]},
    //   content: (
    //     <div className="thank-box">

    //         <img src="../../media/claim-rewards/order-confirmed-11.png" width="248px" />
    //         <p className="thanku">Thanks for your order!</p>
    //         <p className="thank-desc">Thank you for claiming the reward. Your support and trust in us are much appreciated. DIH will contact you to deliver the quantum items.</p>
    //     </div>
    //   )
    // });
    if (sts == 1) setPayment(true);
  }
  useEffect(() => {
    if (payment) updatemycart();
  }, [payment]);

  function updateproductcount(index, type) {
    let newcart = [...cartProducts];
    if (type == "add") {
      newcart[index].count++;
    } else {
      if (newcart[index].count == 1) {
        newcart[index].count = 1;
      } else {
        newcart[index].count--;
      }
    }
    // type == "add" ? newcart[index].count++ : newcart[index].count== 1 ? 1 : newcart[index].count--;

    setCartProducts(newcart);
  }
  function getcarttotal(newcart) {
    let sss = 0;
    newcart.forEach((element) => {
      sss = sss + element.item.points * element.count;
    });
    return sss;
  }

  function destroyAll() {
    Modal.destroyAll();
  }

  const { confirm } = Modal;

  function showConfirm() {
    removeProduct(toRemove);

    // for (let i = 0; i < 3; i += 1) {
    //   setTimeout(() => {
    //     confirm({
    //       icon: <ExclamationCircleOutlined />,
    //       content: <Button onClick={destroyAll}>Click to destroy all</Button>,
    //       onOk() {
    //         console.log('OK');
    //       },
    //       onCancel() {
    //         console.log('Cancel');
    //       },
    //     });
    //   }, i * 500);
    // }
    // const modalRef =
    //     confirm({
    //         icon: <WarningOutlined />,
    //         content: (
    //             <div>
    //                 <p className="confirm-message">Are you sure you want to delete this product?</p>
    //               <div className="confirm-actions">
    //                     <button type="button" className="ant-btn confirm-cancel" onClick={(e)=>{Modal.destroyAll(); setToRemove(-1);}}>
    //                         <span>CANCEL</span>
    //                     </button>
    //                     <button type="button" className="ant-btn confirm-ok" onClick={(e)=>{removeProduct(toRemove)}}>
    //                       <span>OK</span>
    //                     </button>
    //                 </div>
    //             </div>
    //           ),
    //         onOk() {
    //         },
    //         onCancel() {
    //         },

    //       });
  }
  const onFinish = (values) => {
    console.log(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="erp-df">
      { redirect === 1 ? (
        <Redirect to="/learn" />
      ) : (
        <>
          <Modal
            title="title"
            footer={[]}
            centered
            width={1000}
            visible={openhistory}
            onOk={() => {
              console.log("ok");
            }}
            onCancel={() => {
              setOpenhistory(false);
            }}
          >
            <div className="history-box">
              <h2 style={{ lineHeight: "1.5rem" }}>My Order History</h2>
              
              <Table
              locale={{ emptyText: (<p style={{color:"#868E96"}}>
                No Data
                </p>)}}
                columns={columns}
                dataSource={myhistory}
                pagination={true}
              />
            </div>
          </Modal>
          <Modal
            title="title"
            footer={[]}
            centered
            width={1000}
            visible={payment}
            onOk={() => {
              setPayment(false);
              setCartVisible(false);
            }}
            onCancel={() => {
              setPayment(false);
              setCartVisible(false);
            }}
          >
            <div className="thank-box">
              <img
                src={
                  require("../../media/claim-rewards/order-confirmed-11.png")
                    .default
                }
                width="248px"
              />
              <p className="thanku">Thanks for your order!</p>
              <div className="thanku-content">
                <p className="thank-desc">
                  Thank you for claiming the reward. Your support and trust in
                  us are much appreciated. DIH will contact you to deliver the
                  quantum items.
                </p>
              </div>
              <Button
                className="thanku-home"
                onClick={() => {
                  setPayment(false);
                  setCartVisible(false);
                  setRedirect(1);
                }}
              >
                Home
              </Button>
            </div>
          </Modal>
          <Modal
            title="title"
            footer={[]}
            centered
            width={1000}
            visible={conf}
            onOk={() => {
              setPayment(true);
              setCartVisible(true);
              setConf(false);
              info(1);
            }}
            onCancel={() => {
              setPayment(false);
              setConf(false);
            }}
          >
            <div className="thank-box">
              <img
                src={
                  require("../../media/claim-rewards/order-confirmed-11.png")
                    .default
                }
                width="248px"
              />
              <p className="thanku">Confirm Checkout</p>
              <div className="thanku-content">
                <p className="thank-desc">No refund or exchange is permitted</p>
                <p className="thank-desc">
                  Item needs to be collected on the specified date and within
                  the allotted timeframe{" "}
                </p>
                <p className="thank-desc">
                  If you nominate someone else to collect the item on your
                  behalf, he/she needs to present your QR code for collection
                </p>
                <p className="thank-desc">
                  In case of non-collection, the item will be released, and your
                  points will be forfeited.
                </p>
              </div>
              <div className="thanku-actions">
                <Button
                  className="cancel"
                  onClick={() => {
                    setConf(false);
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  className="proceed"
                  onClick={() => {
                    info(1);
                    setConf(false);
                    setPayment(true);
                    setCartVisible(true);
                  }}
                  disabled={cartbalance < 0}
                >
                  Confirm
                </Button>
              </div>
              {/* <Button className="thanku-home" onClick={()=>{ }} ></Button> */}
            </div>
          </Modal>
          <Drawer
            title=""
            placement={placement}
            width={window.innerWidth > 800 ? 574 : "100%"}
            onClose={onClose}
            visible={cartVisible}
            // extra={
            //   <Space>
            //     <Button onClick={onClose}>Cancel</Button>
            //     <Button type="primary" onClick={onClose}>
            //       OK
            //     </Button>
            //   </Space>
            // }
          >
            <div className="cart">
              <p className="title">My Cart</p>
              {cartProducts ? (
                cartProducts.length == 0 ? (
                  <div className="empty-card">
                    <img
                      src={
                        require("../../media/claim-rewards/empty-cart-16.png")
                          .default
                      }
                    />
                    <p>Your cart is empty!</p>
                  </div>
                ) : (
                  <>
                    <div className="cart-products">
                      {cartProducts?.map((product, index) => (
                        <div className="product">
                          <img
                            src={
                              require("../../media/" + product.item?.preview)
                                .default
                            }
                            className="product-img"
                          />
                          <p className="name">{product.item?.name}</p>
                          <div className="counter">
                            <DeleteOutlined
                              onClick={() => {
                                setToRemove(index);
                              }}
                            />
                            {product.count == 1 ? (
                              ""
                            ) : (
                              <MinusOutlined
                                onClick={() => {
                                  updateproductcount(index, "remove");
                                }}
                              />
                            )}
                            <p>{product.count}</p>
                            {/* <InputNumber value={product?.count} onChange={(e)=>console.log(e)} /> */}
                            <PlusOutlined
                              onClick={() => {
                                updateproductcount(index, "add");
                              }}
                            />
                          </div>
                          <p className="price">{product.item?.points} Pts</p>
                        </div>
                      ))}
                    </div>
                    <Divider
                      style={{ borderTop: "2px solid #212529", width: "100%" }}
                    />
                    <div className="payment-details">
                      <p>Cart Total Points</p>
                      <p>{carttprice} Pts</p>
                    </div>
                    <div className="payment-details-mypoints">
                      <p>My Total Points</p>
                      <p>{pagedata ? pagedata[0]?.points : ""} Pts</p>
                    </div>
                    <div className="payment-details-balance">
                      <p>My Balance Points</p>
                      <p
                        style={{
                          color: cartbalance < 0 ? "#FF684D" : "#868E96",
                        }}
                      >
                        {cartbalance} Pts
                      </p>
                    </div>
                    {cartbalance < 0 ? (
                      <div className="payment-details-balance-error">
                        <p>You don't have enough points to checkout</p>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="payment-actions">
                      <Button
                        className="cancel"
                        onClick={() => {
                          onClose();
                        }}
                      >
                        CANCEL
                      </Button>
                      <Button
                        className="proceed"
                        onClick={() => {
                          // info(1)
                          setConf(true);
                        }}
                        disabled={cartbalance < 0}
                      >
                        CHECKOUT
                      </Button>
                    </div>
                  </>
                )
              ) : (
                <div className="empty-card">
                  <img
                    src={
                      require("../../media/claim-rewards/empty-cart-16.png")
                        .default
                    }
                  />
                  <p>Your cart is empty!</p>
                </div>
              )}
            </div>
          </Drawer>
          <Row>
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 6 }}
              className="erp-df-col"
            >
              <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item onClick={() => setRedirect(1)}>
                  <CaretLeftOutlined id="bdcr-icon" />
                  LEARN
                </Breadcrumb.Item>
                <Breadcrumb.Item>CLAIM REWARDS</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 18 }}>
              <div className="claim-reward-col">
                <div className="shopping">
                  <p
                    className="history"
                    onClick={() => {
                      setMyhistory([]);
                      setOpenhistory(true);
                    }}
                  >
                    My Order History
                  </p>
                </div>
                <Divider
                  type="vertical"
                  style={{
                    height: "30px",
                    borderLeft: "2px solid",
                    width: "25px",
                  }}
                />
                <div className="shopping">
                  <a
                    onClick={() => {
                      viewCart();
                    }}
                  >
                    <Badge count={countt}>
                      <ShoppingCartOutlined style={{ fontSize: "38px" }} />
                      {/* <Avatar shape="square" size="large" /> */}
                    </Badge>
                  </a>
                </div>
                <Divider
                  type="vertical"
                  style={{
                    height: "30px",
                    borderLeft: "2px solid",
                    width: "25px",
                  }}
                />
                <div className="points">
                  <p className="rewords-title">POINTS</p>
                  <p className="rewords-points">
                    {pagedata ? pagedata[0]?.points : ""}
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="claim-reward-main">
            <h1 className="title-main">{pagedata ? pagedata[0]?.title : ""}</h1>
            <p className="desc-main-contact-us">
              {pagedata ? pagedata[0]?.description : ""}
            </p>
          </Row>
          <Row className="claim-products">
            {rewardsdata?.length > 0 ? (
              rewardsdata?.map(
                (teammember, index) =>
                  index >= minIndex &&
                  index < maxIndex && (
                    <Col
                      xs={{ span: 24 }}
                      md={{ span: 10 }}
                      lg={{ span: 6 }}
                      onMouseEnter={(e) => {
                        var cardId = "card-" + index;
                        document.getElementById(cardId).style.display =
                          "inline-block";
                      }}
                      onMouseLeave={(e) => {
                        var cardId = "card-" + index;
                        document.getElementById(cardId).style.display = "none";
                      }}
                    >
                      <div className="product-view">
                        <div
                          className={
                            teammember.points <= pagedata[0]?.points
                              ? teammember.status == 0
                                ? "add-cart-available"
                                : teammember.status == 1
                                ? "add-cart-unavailable-out"
                                : "add-cart-unavailable-added"
                              : teammember.status == 1
                              ? "add-cart-unavailable-out"
                              : "add-cart-unavailable"
                          }
                          id={`card-${index}`}
                          style={{ display: "none" }}
                        >
                          <Button
                            className="add-to-cart"
                            onClick={(e) => {
                              if (teammember.status != 2) {
                                updateFieldChanged(index);
                                var productscount = countt;
                                setCountt(productscount + 1);
                              }
                            }}
                          >
                            {" "}
                            {teammember.points <= pagedata[0]?.points
                              ? teammember.status == 0
                                ? "ADD TO CART"
                                : teammember.status == 1
                                ? "OUT OF STOCK"
                                : "ADDED TO CART!"
                              : teammember.status == 1
                              ? "OUT OF STOCK"
                              : "INSUFFICIENT POINTS"}
                          </Button>
                        </div>
                        {teammember.status == 1 ||
                        teammember.points > pagedata[0]?.points ? (
                          <div className="product-unavailable"></div>
                        ) : (
                          ""
                        )}
                        <img
                          src={
                            require("../../media/" + teammember.preview).default
                          }
                          className="product-img"
                          id={`team-profile-${index}`}
                        />
                        <div className="product-info" id={`info-${index}`}>
                          <p className="name">{teammember.name}</p>
                          <p className="desc">
                            {teammember.status == 1 ? (
                              <span style={{ color: "#FF684D" }}>
                                OUT OF STOCK
                              </span>
                            ) : (
                              teammember.points + "Pts"
                            )}{" "}
                          </p>
                        </div>
                      </div>
                    </Col>
                  )
              )
            ) : (
              <Col
                xs={{ span: 24 }}
                md={{ span: 10 }}
                lg={{ span: 4 }}
                onMouseEnter={(e) => {
                  var cardId = "card-e0";
                  document.getElementById(cardId).style.display =
                    "inline-block";
                }}
                onMouseLeave={(e) => {
                  var cardId = "card-e0";
                  document.getElementById(cardId).style.display = "none";
                }}
              >
                <div>
                  <div
                    className="add-cart-available"
                    id={`card-e0`}
                    style={{ display: "none" }}
                  >
                    <Button
                      className="add-to-cart"
                      onClick={() => {
                        var productscount = countt;
                        setCountt(productscount + 1);
                      }}
                    >
                      ADD TO CART
                    </Button>
                  </div>

                  <img
                    src={require("../../media/ERP-Dig-Trans-02.png").default}
                    className="product-img"
                    id={`team-profile-e0`}
                  />
                  <div className="product-info" id={`info-e0`}>
                    <p className="name">name</p>
                    <p className="desc">desc</p>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </>
      )}
    </div>
  );
}
