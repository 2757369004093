import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';

import { Provider } from 'react-redux';
import { store } from './_helpers';
import { App } from './App/index';
import reportWebVitals from './reportWebVitals';

// setup fake backend
import { configureFakeBackend } from './_helpers';

import 'antd/dist/antd.compact.css';
import 'antd/dist/antd.css';


configureFakeBackend();
ReactDOM.render(
  <Provider store={store}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
