import { CaretLeftOutlined, MinusOutlined } from "@ant-design/icons";

import triangleIcon from "../../media/mobile-icons/home.svg";
import { Breadcrumb, Carousel, Col, Row, Select   } from "antd";

import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
const pageSize = 3;
const SamplePrevArrow=(props)=> {
  const { className, style, onClick } = props;
  return (
      <div
      className={className}
      style={{ ...style, display: "block",color:'rgba(0,0,0,0.1)' }}
      onClick={(e)=>{
  console.log("prev",props)
  onClick(e,props)}}
      ><CaretLeftOutlined/></div>
  )
}
const SampleNextArrow = (props)=> {
  const { className, style, onClick } = props
  return (
      <div
      className={className}
      style={{ ...style, display: "block",color:'rgba(0,0,0,0.1)'}}
      onClick={(e)=>{
  console.log("next",props)
  onClick(e,props)}}
      ><CaretLeftOutlined/></div>
  )
}
export default function QImpact() {
  const [deptlist, setDeptlist] = useState([
    "ABKI",
    "AMWAJ",
    "ASMEF",
    "ASOS",
    "ASRE",
    "CONTRACTING & INTERIORS",
    "HOLDING",
    "MOTORS & EUROSTAR",
    "SLBF & MaxRock",
  ]);
  const [slides, setSlides] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [current, setCurrent] = useState(1);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(3);

  const [erpdata, setErpdata] = useState([
    {
      id: 1,
      title: "Ali & Sons Holding - ASH",
      description:
        "Developing a Shared Services Center\r\n.Upgrading the following areas: - Human Resources\r\n- Finance & Payroll\r\n- Procurement & Purchasing\r\n- Credit Control\r\n- Group wide reporting\r\n- Group wide budgeting & \r\nplanning\r\n- Employee Self Service ",
      department: "HOLDING",
      profile: "Q-Impact-ASH.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-20T12:02:59.477",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 2,
      title: "Ali & Sons Marine Engineering Factory -\r\nASMEF",
      description:
        "Upgrading the following areas:\r\n- Automation of Project Costing \r\n& Billing\r\n- Upgrading Project Controls\r\n- Automation of Work Packages\r\n- PMV Process\r\n- Project Budget Controls\r\n- Manpower Forecast\r\n- Employee Self Service",
      department: "ASMEF",
      profile: "Q-Impact-ASMEF.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-20T12:02:59.477",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 3,
      title: "Ali & Sons Oil Field Services - ASOS",
      description:
        "Upgrading the following areas:\r\n- Quote & Sales Order \r\n- Sales Pricing\r\n- Procurement & Inventory\r\n- Employee Self Service",
      department: "ASOS",
      profile: "Q-Impact-ASOS.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-20T12:02:59.477",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 4,
      title: "Ali & Sons Contracting & Interiors (ASC)",
      description:
        "Upgrading the following areas:\r\n- Project Costing & Billing\r\n- Project Controls\r\n- Procurement & Purchasing\r\n- Subcontracts Management\r\n- Automation of Work Packages\r\n- PMV Process\r\n- Project Budget Controls\r\n- Manpower Forecast\r\n- Employee Self Service",
      department: "CONTRACTING & INTERIORS",
      profile: "Q-Impact-ASC.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-20T12:02:59.477",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 5,
      title: "AMWAJ",
      description:
        "Upgrading the following areas:\r\n- Manufacturing\r\n- Procurement\r\n- Finance\r\n- Employee Self Service\r\n- Interface to Jewellery System",
      department: "AMWAJ",
      profile: "Q-Impact-AMWAJ.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-20T12:02:59.477",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 6,
      title: "MaxRock & SL Block Factory",
      description:
        " Upgrading the following areas:\r\n- Manufacturing\r\n- Procurement & Inventory\r\n- Sales Order Processing\r\n- Sales Price Management\r\n- Interface to Core Systems\r\n- Employee Self Service",
      department: "SLBF & MaxRock",
      profile: "Q-Impact-SLBF.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-20T12:02:59.477",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 7,
      title: "Ali & Sons Motors / Inter Emirates Motors \r\n/ Eurostar",
      description:
        "Upgrading the following areas:\r\n- Interface to DMS & Gateway\r\n- Central Purchasing\r\n- Employee Self Service",
      department: "MOTORS & EUROSTAR",
      profile: "Q-Impact-Motors.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-20T12:02:59.477",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 8,
      title: "ABKI",
      description:
        "Upgrading the following areas:\r\n- Finance\r\n- HR & Payroll\r\n- Employee Self Service",
      department: "ABKI",
      profile: "Q-Impact-ABKI.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-20T12:02:59.477",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 9,
      title: "Ali & Sons Real Estate - ASRE",
      description:
        "Upgrading the following areas:\r\n- Finance\r\n- HR & Payroll\r\n- Employee Self Service",
      department: "ASRE",
      profile: "Q-Impact-ASRE.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-20T12:02:59.477",
      u_status: "success",
      u_errormsg: "",
    },
  ]);
  const [erpdataf, setErpdataf] = useState([]);
  const [selected, setSelected] = useState("ALL");
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
}
  const [redirect, setRedirect] = useState(0);
  const [styleConn, setStyleConn] = useState({ display: "", i: -1 });
  useEffect(() => {
    setTotalPage(erpdata.length / pageSize);
    setMinIndex(0);
    setMaxIndex(pageSize);

    stopintr();
    startintr();
    let mm = document.getElementById("main-menu")?.getElementsByTagName("li");
    for (let index = 0; index < mm?.length; index++) {
      const element = mm[index];
      if (element) {
        element.classList.remove("ant-menu-item-selected");
        element.classList.remove("ant-menu-item-active");
      }
    }
    if (mm?.length > 1) {
      mm[1].classList.add("ant-menu-item-selected");
    }
    window.scrollTo(0, 0);

  }, []);

  useEffect(() => {
    if (erpdata.length > 0) {
      let temp = [];
      if (pageSize > erpdata.length) {
        temp.push(erpdata?.slice(minIndex, erpdata.length));
      } else {
        let min = minIndex;
        for (
          let index = pageSize;
          index < erpdata.length;
          index = index + pageSize
        ) {
          temp.push(erpdata?.slice(min, index));
          min += pageSize;
          if (index + pageSize > erpdata.length - 1) {
            temp.push(erpdata?.slice(index, erpdata.length));
          }
        }
      }

      //   setSlides(temp);

      let er = { slides: [] };
      temp.forEach((ar) => {
        er.slides.push(ar);
      });
      setSlides(er);
    }
  }, [erpdata]);
  useEffect(() => {
    if (erpdataf.length > 0) {
      let temp = [];
      if (pageSize > erpdataf.length) {
        temp.push(erpdataf?.slice(minIndex, erpdataf.length));
      } else {
        let min = minIndex;
        for (
          let index = pageSize;
          index < erpdataf.length;
          index = index + pageSize
        ) {
          temp.push(erpdataf?.slice(min, index));
          min += pageSize;
          if (index + pageSize > erpdataf.length - 1) {
            temp.push(erpdataf?.slice(index, erpdataf.length));
          }
        }
      }

      //   setSlides(temp);
      let er = { slides: [] };
      temp.forEach((ar) => {
        er.slides.push(ar);
      });
      setSlides(er);
    } else {
      let temp = [];
      if (pageSize > erpdata.length) {
        temp.push(erpdata?.slice(minIndex, erpdata.length));
      } else {
        let min = minIndex;
        for (
          let index = pageSize;
          index < erpdata.length;
          index = index + pageSize
        ) {
          temp.push(erpdata?.slice(min, index));
          min += pageSize;
          if (index + pageSize > erpdata.length - 1) {
            temp.push(erpdata?.slice(index, erpdata.length));
          }
        }
      }

      //   setSlides(temp);
      let er = { slides: [] };
      temp.forEach((ar) => {
        er.slides.push(ar);
      });
      setSlides(er);
    }
  }, [erpdataf]);
  useEffect(() => {}, [slides]);
  function startintr() {
    setInterval(() => {
      let prev = styleConn;
      prev.display = "none";
      if (prev.i < erpdata.length) {
        prev.i = prev.i + 1;
      } else {
        prev.i = 0;
      }
      setStyleConn({ display: prev.display, i: prev.i });
    }, 3000);
  }
  function stopintr() {
    const interval_id = window.setInterval(function () {},
    Number.MAX_SAFE_INTEGER);

    // Clear any timeout/interval up to that id
    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }
  }

  useEffect(() => {
    setRedirect(0);
  }, [redirect]);

  return (
    <div className="erp-df">
      {redirect === 1 ? (
        <Redirect to="/connect" />
      ) : (
        <Row>
          <Col
          xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item onClick={() => setRedirect(1)}>
                <CaretLeftOutlined id="bdcr-icon" />
                CONNECT
              </Breadcrumb.Item>
              <Breadcrumb.Item>QUANTUM IMPACT</Breadcrumb.Item>
            </Breadcrumb>
            </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 4 }}
            className="erp-df-col"
          >
            
            {/* <h1 className="title-main">Quantum Impact</h1> */}
            <p className="subtitle-main">
              For each business Quantum will help us to improve and change the
              following areas:
            </p>
            {/* {window.innerWidth > 980? */}
            {/* <div className="filter-erp">
              <p className="filter-title">SELECT :</p>
              <p
                className={selected == 'ALL' ? 'selected' : ''}
                onClick={() => {
                  // window.location.reload()
                  setErpdataf(erpdata);
                  setSelected('ALL');
                }}
              >
                ALL
              </p>
              {deptlist?.map((dept, index) => (
                <p
                  className={selected == dept ? 'selected' : ''}
                  onClick={() => {
                    setErpdataf(erpdata.filter((t) => t.department == dept));
                    setSelected(dept);
                  }}
                >
                  {dept}
                </p>
              ))}
            </div>: */}
            <div className="filter-imp">
              <p className="filter-title">SELECT :</p>
              <Select
                onChange={(dept) => {
                  if (dept == "ALL") {
                    setErpdataf([]);
                    setSelected("ALL");
                  } else {
                    let filter = erpdata.filter((t) =>
                      t.department.includes(dept)
                    );
                    setErpdataf(filter);
                    setTotalPage(filter.length);
                    setCurrent(0);
                    setSelected(dept);
                  }
                }}
                defaultValue={"ALL"}
                style={{ width: "100%" }}
              >
                <Select.Option value="ALL">ALL</Select.Option>
                {deptlist?.map((dept, index) => (
                  <Select.Option value={dept}>{dept}</Select.Option>
                ))}
              </Select>
            </div>
            {/* } */}
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 20 }}
            className="erp-df-slider-col"
          >
            
              <Carousel arrows={true} {...settings}
              className="slidescarousel">
                {slides
                  ? slides.slides?.map((s, i) => (
                      <div key={i}>
                        {s?.map((ss, ii) => (
                          <div
                            onMouseEnter={(e) => {
                              stopintr();
                              setStyleConn({ display: "none", i: ii });
                            }}
                            onMouseLeave={(e) => {
                              startintr();
                              setStyleConn({ display: "block", i: ii });
                            }}
                          >
                            <img
                              src={require("../../media/pictures-q-impact/" + ss.profile).default}
                              style={{
                                display:
                                  styleConn.display == "none" &&
                                  styleConn.i == ii
                                    ? "none"
                                    : "block",
                              }}
                              className={`contentStyle`}
                              
                            />
                            <div
                            style={{backgroundImage:`url(${require("../../media/pictures-q-impact/Hover-" + ss.profile).default})`}}
                              className={`bottom-center ${
                                styleConn.display == "none" && styleConn.i == ii
                                  ? "hovered"
                                  : ""
                              }`}
                            >
                              <p
                                className="title"
                                style={{
                                  display:
                                    styleConn.display == "none" &&
                                    styleConn.i == ii
                                      ? "none"
                                      : "block",
                                }}
                              >
                                {ss.title}
                              </p>
                              <div
                                className="slide-content"
                                style={{
                                  display:
                                    styleConn.display == "none" &&
                                    styleConn.i == ii
                                      ? "block"
                                      : "none",
                                }}
                              >
                                <p className="title">{ss.title}</p>
                                {ss.description.split("-").length > 1 ? (
                                  ss.description.split("-").map((e, i) =>
                                    e.split(".").length > 1 ? (
                                      e.split(".").map((ee, ii) => (
                                        <div
                                          style={{
                                            display: "grid",
                                            gridAutoFlow: "column",
                                            justifyContent: "start",
                                          }}
                                        >
                                          {/* <RightOutlined
                                            style={{
                                              fontSize: '12px',
                                              lineHeight: 2,
                                              marginRight: '0.5rem',
                                            }}
                                          /> */}
                                          <img
                                            src={triangleIcon}
                                            style={{
                                              width: "0.8rem",
                                              marginRight: "0.5rem",
                                              marginTop: "0.3rem",
                                            }}
                                          />

                                          <p className="desc-t">{ee}</p>
                                        </div>
                                      ))
                                    ) : i == 0 ? (
                                      <div
                                        style={{
                                          display: "grid",
                                          gridAutoFlow: "column",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <img
                                          src={triangleIcon}
                                          style={{
                                            width: "0.8rem",
                                            marginRight: "0.5rem",
                                            marginTop: "0.3rem",
                                          }}
                                        />

                                        <p className="desc-t">{e}</p>
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          display: "grid",
                                          gridAutoFlow: "column",
                                          justifyContent: "start",
                                          
                                        }}
                                        className="slide-list"
                                      >
                                        <MinusOutlined
                                          style={{
                                            fontSize: "12px",
                                            lineHeight: 2,
                                            marginRight: "0.5rem",
                                          }}
                                        />

                                        <p className="desc">{e}</p>
                                      </div>
                                    )
                                  )
                                ) : (
                                  <p className="desc">{ss.description}</p>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))
                  : ""}
              </Carousel>
            
              <Row className="slidesrow">
                {erpdataf.length > 0
                  ? erpdataf.map((ss, ii) => (
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <div
                          onMouseEnter={(e) => {
                            stopintr();
                            setStyleConn({ display: "none", i: ii });
                          }}
                          onMouseLeave={(e) => {
                            startintr();
                            setStyleConn({ display: "block", i: ii });
                          }}
                        >
                          <img
                            src={require("../../media/pictures-q-impact/" + ss.profile).default}
                            style={{
                              display:
                                styleConn.display == "none" &&
                                styleConn.i == ii
                                  ? "none"
                                  : "block",
                            }}
                            className="contentStyle"
                          />
                          <div
                          style={{backgroundImage:`url(${require("../../media/pictures-q-impact/Hover-" + ss.profile).default})`}}
                            className={`bottom-center ${
                              styleConn.display == "none" && styleConn.i == ii
                                ? "hovered"
                                : ""
                            }`}
                          >
                            <p
                              className="title"
                              style={{
                                display:
                                  styleConn.display == "none" &&
                                  styleConn.i == ii
                                    ? "none"
                                    : "block",
                              }}
                            >
                              {ss.title}
                            </p>
                            <div
                              className="slide-content"
                              style={{
                                display:
                                  styleConn.display == "none" &&
                                  styleConn.i == ii
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <p className="title">{ss.title}</p>
                              {ss.description.split("-").length > 1 ? (
                                ss.description.split("-").map((e, i) =>
                                  e.split(".").length > 1 ? (
                                    e.split(".").map((ee, ii) => (
                                      <div
                                        style={{
                                          display: "grid",
                                          gridAutoFlow: "column",
                                          justifyContent: "start",
                                        }}
                                      >
                                        {/* <RightOutlined
                                            style={{
                                              fontSize: '12px',
                                              lineHeight: 2,
                                              marginRight: '0.5rem',
                                            }}
                                          /> */}
                                        <img
                                          src={triangleIcon}
                                          style={{
                                            width: "0.8rem",
                                            marginRight: "0.5rem",
                                            marginTop: "0.3rem",
                                          }}
                                        />

                                        <p className="desc-t">{ee}</p>
                                      </div>
                                    ))
                                  ) : i == 0 ? (
                                    <div
                                      style={{
                                        display: "grid",
                                        gridAutoFlow: "column",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <img
                                        src={triangleIcon}
                                        style={{
                                          width: "0.8rem",
                                          marginRight: "0.5rem",
                                          marginTop: "0.3rem",
                                        }}
                                      />

                                      <p className="desc-t">{e}</p>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "grid",
                                        gridAutoFlow: "column",
                                        justifyContent: "start",
                                        
                                      }}
                                      className="slide-list"
                                    >
                                      <MinusOutlined
                                        style={{
                                          fontSize: "12px",
                                          lineHeight: 2,
                                          marginRight: "0.5rem",
                                        }}
                                      />

                                      <p className="desc">{e}</p>
                                    </div>
                                  )
                                )
                              ) : (
                                <p className="desc">{ss.description}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))
                  : erpdata.map((ss, ii) => (
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <div
                          onMouseEnter={(e) => {
                            stopintr();
                            setStyleConn({ display: "none", i: ii });
                          }}
                          onMouseLeave={(e) => {
                            startintr();
                            setStyleConn({ display: "block", i: ii });
                          }}
                        >
                          <img
                            src={require("../../media/pictures-q-impact/" + ss.profile).default}
                            style={{
                              display:
                                styleConn.display == "none" &&
                                styleConn.i == ii
                                  ? "none"
                                  : "block",
                            }}
                            className="contentStyle"
                          />
                          <div
                          style={{backgroundImage:`url(${require("../../media/pictures-q-impact/Hover-" + ss.profile).default})`}}
                            className={`bottom-center ${
                              styleConn.display == "none" && styleConn.i == ii
                                ? "hovered"
                                : ""
                            }`}
                          >
                            <p
                              className="title"
                              style={{
                                display:
                                  styleConn.display == "none" &&
                                  styleConn.i == ii
                                    ? "none"
                                    : "block",
                              }}
                            >
                              {ss.title}
                            </p>
                            <div
                              className="slide-content"
                              style={{
                                display:
                                  styleConn.display == "none" &&
                                  styleConn.i == ii
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <p className="title">{ss.title}</p>
                              {ss.description.split("-").length > 1 ? (
                                ss.description.split("-").map((e, i) =>
                                  e.split(".").length > 1 ? (
                                    e.split(".").map((ee, ii) => (
                                      <div
                                        style={{
                                          display: "grid",
                                          gridAutoFlow: "column",
                                          justifyContent: "start",
                                        }}
                                      >
                                        {/* <RightOutlined
                                            style={{
                                              fontSize: '12px',
                                              lineHeight: 2,
                                              marginRight: '0.5rem',
                                            }}
                                          /> */}
                                        <img
                                          src={triangleIcon}
                                          style={{
                                            width: "0.8rem",
                                            marginRight: "0.5rem",
                                            marginTop: "0.3rem",
                                          }}
                                        />

                                        <p className="desc-t">{ee}</p>
                                      </div>
                                    ))
                                  ) : i == 0 ? (
                                    <div
                                      style={{
                                        display: "grid",
                                        gridAutoFlow: "column",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <img
                                        src={triangleIcon}
                                        style={{
                                          width: "0.8rem",
                                          marginRight: "0.5rem",
                                          marginTop: "0.3rem",
                                        }}
                                      />

                                      <p className="desc-t">{e}</p>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "grid",
                                        gridAutoFlow: "column",
                                        justifyContent: "start",
                                        
                                      }}
                                      className="slide-list"
                                    >
                                      <MinusOutlined
                                        style={{
                                          fontSize: "12px",
                                          lineHeight: 2,
                                          marginRight: "0.5rem",
                                        }}
                                      />

                                      <p className="desc">{e}</p>
                                    </div>
                                  )
                                )
                              ) : (
                                <p className="desc">{ss.description}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
              </Row>
            
          </Col>
        </Row>
      )}
    </div>
  );
}
