// App.js
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from '../theme';
import { GlobalStyles } from '../global';
import { Layout } from 'antd';
import { connect } from 'react-redux';

import { Router, Route } from 'react-router-dom';
import {render} from 'react-dom';
import HeaderSection from '../components/HeaderSection';
import { Content } from 'antd/lib/layout/layout';
import FooterSection from '../components/FooterSection';
import Home from '../Quantum/Home';
import ContactUs from '../Quantum/ContactUs';
import Qpass from '../Quantum/LearnSection/Qpass';
import QHome from '../Quantum/QHome';
import WhatIsQuantum from '../Quantum/ConnectSection/WhatIsQuantum';
import QImpact from '../Quantum/ConnectSection/QImpact';
import QPhases from '../Quantum/ConnectSection/QPhases';
import MeetTheTeam from '../Quantum/ConnectSection/MeetTheTeam';
import EmployeeGuide from '../Quantum/LearnSection/EmployeeGuide';
import WatchVideos from '../Quantum/SeeSection/WatchVideos';
import Gallery from '../Quantum/SeeSection/Gallery';
import MyLearning from '../Quantum/LearnSection/MyLearning';
import ClaimRewards from '../Quantum/LearnSection/ClaimRewards';

import { history } from '../_helpers';
import { alertActions } from '../_actions';


const localtheme = localStorage.getItem('theme');



class App extends React.Component {
  constructor(props) {
      super(props);
      
     
     
      this.state = {
        theme:localtheme?localtheme:'dark',
        location:window.location.pathname
      }
      
      
      const { dispatch } = this.props;
      history.listen((location, action) => {
          // clear alert on location change
          dispatch(alertActions.clear());
      });
  }
  
componentDidMount(){
  let mm = document.getElementById('main-menu')?.getElementsByTagName('li');
  for (let index = 0; index < mm?.length; index++) {
    const element = mm[index];
    if(element){
      
      element.classList.remove("ant-menu-item-selected");
      element.classList.remove("ant-menu-item-active");
    }
  }
  if(mm?.length > 1){

    if(window.location.pathname == "/q-phases" || window.location.pathname == "/q-impact" 
    ||window.location.pathname == "/meet-the-team"||window.location.pathname == "/what-is-quantum" 
    || window.location.pathname == "/connect"){
      mm[1].classList.add("ant-menu-item-selected")
    }
    if(window.location.pathname == "/admin"){
      mm[0].classList.add("ant-menu-item-selected")
    }
    if(window.location.pathname == "/employee-guide" || window.location.pathname == "/claim-rewards"
    || window.location.pathname == "/mylearning" || window.location.pathname == "/learn"){
      mm[2].classList.add("ant-menu-item-selected")
    }
    if(window.location.pathname == "/watch-videos"|| window.location.pathname == "/gallery"|| window.location.pathname == "/see"){
      mm[3].classList.add("ant-menu-item-selected")

    }
    if(window.location.pathname == "/q-pass"){
      mm[5].classList.add("ant-menu-item-selected")

    }
  }
  
}
render() {
    const toggleTheme = () => {
        if (this.state.theme === 'light') {
          localStorage.setItem('theme', 'dark');
          this.setState({theme:'dark'});
        } else {
          localStorage.setItem('theme', 'light');
          this.setState({theme:'light'});
        }
      }
    const setLocation = (url) => {
        this.setState({location:url});
      }
      const { alert } = this.props;
      const {theme , location} = this.state;
      return (
          <>
              {alert.message &&
                  <div>{alert.message}</div>
              }
              
              <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <>
        <GlobalStyles />
        {/* <h1>It's a {theme === 'light' ? 'light theme' : 'dark theme'}!</h1> */}
        

          <Router history={history}>
        <Layout>
          <HeaderSection setLocation={setLocation} theme={theme} toggleTheme={toggleTheme} />
          <Content>
            
            <Route exact path="/home/:token?" component={Home} />
            <Route exact path="/" component={QHome} />

            <Route exact path="/connect" component={QHome} />
            <Route exact path="/what-is-quantum" component={WhatIsQuantum} />
            <Route exact path="/q-impact" component={QImpact} />
            <Route exact path="/meet-the-team" component={MeetTheTeam} />
            <Route exact path="/q-phases" component={QPhases}/>

            <Route exact  path="/see" component={QHome} />
            <Route exact path="/watch-videos" component={WatchVideos} />
            <Route exact path="/gallery" component={Gallery} />
              
            <Route exact  path="/learn" component={QHome} />
            <Route exact path="/employee-guide" component={EmployeeGuide} />
            <Route path="/claim-rewards" component={ClaimRewards} />
            <Route exact path="/mylearning" component={MyLearning} />

            <Route exact path="/q-pass" component={Qpass} />

            <Route exact  path="/contact-us" component={ContactUs}  />
            

            {/* <Route path="/admin" component={Admin} /> */}
               
              
              
             
          </Content>
          <FooterSection theme={theme} toggleTheme={toggleTheme} />
        </Layout>
          </Router>
      </>
    </ThemeProvider>
          </>
      );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
      alert
  };
}

const connectedApp = connect(mapStateToProps)(App);
export  { connectedApp as App };