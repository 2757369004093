import { Footer } from 'antd/lib/layout/layout'
import React, { Component } from 'react'
import Toggle from '../Toggle'

export default class FooterSection extends Component {
    render() {
        const { theme, toggleTheme } = this.props;
        return (
            <Footer className="footer">
                    <Toggle theme={theme} toggleTheme={toggleTheme} />
            </Footer>
        )
    }
}
