// theme.js

export const lightTheme = {
    body: '#fafafa',
    text: '#212529',
    toggleBorder: '#FFF',
    gradient: 'linear-gradient(#39598A, #79D7ED)',
    textboxbackground:'#EBEBEB',
    textdisapled:'rgba(0,0,0,0.25)',
    tablebody:'#141414',
    tableth:'#262626',
    tabletext:'#fff',
    tabletexth:'#a2a2a2',
    paginationdisabledlink:'rgba(0,0,0,0.2)',
    tourColor:'#212529'
  }
  
  export const darkTheme = {
    body: '#212529',
    text: '#FAFAFA',
    toggleBorder: '#6B8096',
    gradient: 'linear-gradient(#091236, #1E215D)',
    textboxbackground:'#3D444C',
    textdisapled:'rgba(255,255,255,0.25)',
    tablebody:'#141414',
    tableth:'#262626',
    tabletext:'#FAFAFA',
    tabletexth:'#a2a2a2',
    paginationdisabledlink:'rgba(255,255,255,0.3)',
    tourColor:'#212529'
  }