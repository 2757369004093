import { CaretLeftOutlined } from "@ant-design/icons";
import { Breadcrumb, Col, Row } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import thump from "../../media/pdfthum.png";
import { saveAs } from "file-saver";
import MyPDF from "../../media/documents/Employee Guide.pdf";

export default function EmployeeGuide() {

  const [redirect, setRedirect] = useState(0);
  const [empcdata, setEmpcdata] = useState({
    id: 1,
    title: "EMPLOYEE GUIDE",
    description:
      "This guide provides you details about Quantum in terms of What, Why, Who and How Quantum will change the way we work. It gives an overview about what is expected from you and what you should expect from Quantum.",
    p_user: "00000253",
    create_byName: "Sandra Altheeb Hnaidy",
    createdon: "2021-10-24T12:08:46.063",
    u_status: "success",
    u_errormsg: "",
    pdfFiles: [
      {
        id: 1,
        title: "Employee Guide",
        url: "emp guide.pptx",
        guideModuleElementId: 0,
        guideModuleElement: null,
        p_user: null,
        create_byName: null,
        createdon: null,
        u_status: null,
        u_errormsg: null,
      },
    ],
  });
  const [files, setFiles] = useState(null);

  useEffect(() => {
    let mm = document.getElementById("main-menu")?.getElementsByTagName("li");
    for (let index = 0; index < mm?.length; index++) {
      const element = mm[index];
      if (element) {
        element.classList.remove("ant-menu-item-selected");
        element.classList.remove("ant-menu-item-active");
      }
    }
    if (mm?.length > 1) {
      mm[2].classList.add("ant-menu-item-selected");
    }
    window.scrollTo(0, 0);

  }, []);

  const axois_downloaddata = async (filename, filenamae1) => {
    try {
      let UserFromApi = await axios.post(
        process.env.REACT_APP_API + "getdocument_file",
        { page_url: filename, file_name: filenamae1 },
        { responseType: "blob" }
      );
      if (UserFromApi.data) {
        var blob = new Blob([UserFromApi.data]);
        saveAs(blob, filenamae1);
      }
    } catch (error) {
      console.log(
        "Error Loading approval status values **** : ",
        error.message
      );
    }
  };

  useEffect(() => {
    setRedirect(0);
  }, [redirect]);

  return (
    <div className="erp-df">
      {redirect === 1 ? (
        <Redirect to="/learn" />
      ) : (
        <>
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item onClick={() => setRedirect(1)}>
              <CaretLeftOutlined id="bdcr-icon" />
              LEARN
            </Breadcrumb.Item>
            <Breadcrumb.Item>{empcdata?.title}</Breadcrumb.Item>
          </Breadcrumb>
          <Row className="emp-guide-content">
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              className="erp-df-col"
            >
              <h1 className="title-main">
                {empcdata?.title != "" ? empcdata?.title : "Employee \n Guide"}
              </h1>

              <p className="subtitle-main">
                {empcdata?.description != ""
                  ? empcdata?.description
                  : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's since the 1500s"}
              </p>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              className="erp-df-col2"
            >
              {files ? (
                files.map((file, index) => (
                  <p
                    onClick={() => {
                      axois_downloaddata(file.url, file.nametosave);
                    }}
                    onMouseEnter={(e) => {
                      var fileId = "file-" + index;
                      document.getElementById(fileId).style.transform =
                        "scale(1.5)";
                    }}
                    onMouseLeave={(e) => {
                      var fileId = "file-" + index;
                      document.getElementById(fileId).style.transform =
                        "scale(1)";
                    }}
                  >
                    <div
                      className="v-slide-cover"
                      style={{
                        width: "100%",
                        display: "block",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      <img
                        src={thump}
                        className="file-icon"
                        id={`file-${index}`}
                      ></img>
                    </div>
                    <p className="f-title">{file.title}</p>
                  </p>
                ))
              ) : (
                <>
                  <a
                    href={MyPDF}
                    download="EmployeeGuide.pdf"
                    onMouseEnter={(e) => {
                      var fileId = "file-0";
                      document.getElementById(fileId).style.transform =
                        "scale(1.5)";
                    }}
                    onMouseLeave={(e) => {
                      var fileId = "file-0";
                      document.getElementById(fileId).style.transform =
                        "scale(1)";
                    }}
                  >
                    <div
                      className="v-slide-cover"
                      style={{
                        width: "100%",
                        display: "block",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      <img
                        src={thump}
                        className="file-icon"
                        id={`file-0`}
                      ></img>
                    </div>{" "}
                    <p className="f-title">Employee Guide</p>{" "}
                  </a>
                </>
              )}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
