import { CaretLeftOutlined } from "@ant-design/icons";
import { Breadcrumb, Col, Image, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";

const pageSize = 10;

export default function MeetTheTeam() {
  const [deptlist, setDeptlist] = useState([
    "PROJECT STEERING COMMITTEE",
    "ERP TEAM",
  ]);
  const [teamdata, setTeamdata] = useState([
    {
      id: 28,
      name: "Shumon-Zaman",
      description: "ERP TEAM",
      department: "ERP TEAM",
      profile: "Profile-Pictures/Proj-Steering-Comm/5-Shumon-Zaman.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-25T14:59:14.923",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 24,
      name: "M-Junaid",
      description: "ERP TEAM",
      department: "ERP TEAM",
      profile: "Profile-Pictures/ERP-Team/M-Junaid.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-25T14:59:14.923",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 23,
      name: "Syed-Tasaduq",
      description: "ERP TEAM",
      department: "ERP TEAM",
      profile: "Profile-Pictures/ERP-Team/Syed-Tasaduq.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-25T14:59:14.923",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 22,
      name: "Sohail-Iqbal",
      description: "ERP TEAM",
      department: "ERP TEAM",
      profile: "Profile-Pictures/ERP-Team/Sohail-Iqbal.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-25T14:59:14.923",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 25,
      name: "Essmaeel-Ali",
      description: "ERP TEAM",
      department: "ERP TEAM",
      profile: "Profile-Pictures/ERP-Team/Essmaeel-Ali.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-25T14:59:14.923",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 26,
      name: "M-Safeer",
      description: "ERP TEAM",
      department: "ERP TEAM",
      profile: "Profile-Pictures/ERP-Team/M-Safeer.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-25T14:59:14.923",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 1,
      name: "Shamis-Al-Dhaheri",
      description: "PROJECT COMMITTEE",
      department: "PROJECT STEERING COMMITTEE",
      profile: "Profile-Pictures/Proj-Steering-Comm/1-Shamis-Al-Dhaheri.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-25T14:50:24.777",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 2,
      name: "Moh-Al-Dhaheri",
      description: "PROJECT COMMITTEE",
      department: "PROJECT STEERING COMMITTEE",
      profile: "Profile-Pictures/Proj-Steering-Comm/2-Moh-Al-Dhaheri.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-25T14:50:24.777",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 3,
      name: "Saeed-Al-Dhaheri",
      description: "PROJECT COMMITTEE",
      department: "PROJECT STEERING COMMITTEE",
      profile: "Profile-Pictures/Proj-Steering-Comm/3-Saeed-Al-Dhaheri.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-25T14:50:24.777",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 4,
      name: "Emad-Elatreby",
      description: "PROJECT COMMITTEE",
      department: "PROJECT STEERING COMMITTEE",
      profile: "Profile-Pictures/Proj-Steering-Comm/4-Emad-Elatreby.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-25T14:50:24.777",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 27,
      name: "Shumon-Zaman",
      description: "PROJECT COMMITTEE",
      department: "PROJECT STEERING COMMITTEE",
      profile: "Profile-Pictures/Proj-Steering-Comm/5-Shumon-Zaman.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-25T14:59:14.923",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 29,
      name: "joseph-kourieh",
      description: "PROJECT COMMITTEE",
      department: "PROJECT STEERING COMMITTEE",
      profile: "Profile-Pictures/Proj-Steering-Comm/joseph-kourieh.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-25T14:59:14.923",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 9,
      name: "Nasser-Alblooshi",
      description: "PROJECT COMMITTEE",
      department: "PROJECT STEERING COMMITTEE",
      profile: "Profile-Pictures/Proj-Steering-Comm/8-Nasser-Alblooshi.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-25T14:59:14.253",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 10,
      name: "Osama-Aldayyat",
      description: "PROJECT COMMITTEE",
      department: "PROJECT STEERING COMMITTEE",
      profile: "Profile-Pictures/Proj-Steering-Comm/9-Osama-Aldayyat.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-25T14:59:14.923",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 7,
      name: "Mark-Austin",
      description: "PROJECT COMMITTEE",
      department: "PROJECT STEERING COMMITTEE",
      profile: "Profile-Pictures/Proj-Steering-Comm/7-Mark-Austin.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-25T14:50:24.777",
      u_status: "success",
      u_errormsg: "",
    },
    {
      id: 6,
      name: "Wissam-Khalil",
      description: "PROJECT COMMITTEE",
      department: "PROJECT STEERING COMMITTEE",
      profile: "Profile-Pictures/Proj-Steering-Comm/6-Wissam-Khalil.png",
      p_user: "00000253",
      create_byName: "Sandra Altheeb Hnaidy",
      createdon: "2021-10-25T14:50:24.777",
      u_status: "success",
      u_errormsg: "",
    },
  ]);
  const [teamdataf, setTeamdataf] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [current, setCurrent] = useState(1);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);
  const [redirect, setRedirect] = useState(0);
  const [selected, setSelected] = useState("PROJECT STEERING COMMITTEE");

  useEffect(() => {
    setTeamdataf(teamdata.filter((e) => e.department == deptlist[0]));

    setTotalPage(teamdataf.length / pageSize);
    setMinIndex(0);
    setMaxIndex(pageSize);

    let mm = document.getElementById("main-menu")?.getElementsByTagName("li");
    for (let index = 0; index < mm?.length; index++) {
      const element = mm[index];
      if (element) {
        element.classList.remove("ant-menu-item-selected");
        element.classList.remove("ant-menu-item-active");
      }
    }
    if (mm?.length > 1) {
      mm[1].classList.add("ant-menu-item-selected");
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setRedirect(0);
  }, [redirect]);

  return (
    <div className="erp-df">
      {redirect === 1 ? (
        <Redirect to="/connect" />
      ) : (
        <Row>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 7 }}
            className="erp-df-col"
          >
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item onClick={() => setRedirect(1)}>
                <CaretLeftOutlined id="bdcr-icon" />
                CONNECT
              </Breadcrumb.Item>
              <Breadcrumb.Item>QUANTUM TEAM</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="title-main">Meet the team driving Quantum</h1>
            {selected == deptlist[0] ? (
              <>
                <p className="subtitle-main">
                  The Quantum Governance Team (QGT) is responsible for ensuring
                  any hurdles that impact the project are cleared so the project
                  team can deliver the project successfully.
                </p>
                <p className="subtitle-main">
                  It is chaired by Shamis Al Dhaheri – Group Managing Director
                  and includes some of the Executive Management Team along with
                  senior management.
                </p>
                <p className="subtitle-main">
                  The QGT will closely monitor the project and provide the
                  necessary support to ensure we all win with this project.
                </p>
              </>
            ) : (
              <>
                <p className="subtitle-main">
                  Success in digital transformation projects is not solely from
                  the technology being deployed but the team driving the change.
                </p>
                <p className="subtitle-main">
                  Our team consists of Samurai’s and Ninja’s that are
                  multi-disciplined, assembled from around the globe.{" "}
                </p>
                <p className="subtitle-main">
                This team have gone through similar digital transformations and have delivered million-dollar savings in the process – so they have what it takes to help us deliver this project successfully. They keep the destination in sight, but they do this with a love for the journey.
                </p>
                <p className="subtitle-main-highlight">
                  “Successful project delivery within budget and timelines is
                  just as important as the having-fun part. That’s why we’re a
                  team. We go further together than we do apart.”
                </p>
                <p className="subtitle-main-highlight">
                  Shumon A Zaman – Chief Information & Digital Officer
                </p>
              </>
            )}
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 17 }}
            className="erp-df-slider-col"
          >
            <div className="filter-team">
              <p className="filter-title">SELECT :</p>
              <Select
                onChange={(dept) => {
                  if (dept == "ALL") {
                    setTeamdataf(teamdata);
                    setSelected("ALL");
                  } else {
                    let filter = teamdata.filter((t) =>
                      t.department.includes(dept)
                    );
                    setTeamdataf(filter);
                    setTotalPage(filter.length);
                    setCurrent(0);
                    setSelected(dept);
                  }
                }}
                defaultValue={deptlist[0]}
                style={{ width: "300px" }}
              >
                {/* <Select.Option value="ALL">ALL</Select.Option> */}
                {deptlist?.map((dept, index) => (
                  <Select.Option value={dept}>{dept}</Select.Option>
                ))}
              </Select>
            </div>
            <div style={{ minHeight: "515px" }}>
              {window.innerWidth > 900 ? (
                <Row className="team-view">
                  <div>
                    <Image
                      src={
                        teamdataf[0]?.profile
                          ? require("../../media/" + teamdataf[0]?.profile)
                              .default
                          : ""
                      }
                      className="team-avatar"
                      id={`team-profile-f-${0}`}
                    />
                  </div>

                  <Row
                    className="team-all"
                    style={{
                      gridTemplateRows:
                        selected == deptlist[0]
                          ? "repeat(3,1fr)"
                          : "repeat(2,1fr)",
                    }}
                  >
                    {teamdataf?.map((teammember, index) =>
                      index != 0 ? (
                        <Col
                          style={{
                            gridColumnStart:
                              index > 3
                                ? index > 6
                                  ? index - 6
                                  : index - 3
                                : index,
                            gridColumnEnd:
                              index > 3
                                ? index > 6
                                  ? index - 6 + 1
                                  : index - 3 + 1
                                : index + 1,
                          }}
                          //  xs={{ span: 11 }} md={{ span: 10 }} lg={{ span: 7 }}
                        >
                          <div>
                            <Image
                              src={
                                require("../../media/" + teammember.profile)
                                  .default
                              }
                              className="team-avatar"
                              id={`team-profile-f-${index}`}
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )
                    )}
                  </Row>
                </Row>
              ) : (
                <Row>
                  {teamdataf?.map((teammember, index) => (
                    <Col>
                      <div>
                        <Image
                          src={
                            require("../../media/" + teammember.profile).default
                          }
                          className="team-avatar"
                          id={`team-profile-f-${index}`}
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}
